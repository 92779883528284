import React, { useRef, useEffect, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { useAnimations, useGLTF } from '@react-three/drei';
import { MeshStandardMaterial } from 'three';
import { TextureLoader } from 'three';
import * as THREE from 'three';

import { useUser } from "../context/userContext";

const ZenModel = ({ isHolding, hasMana, ...props }) => {
  const groupRef = useRef();
  const { nodes, animations } = useGLTF('/zenmodelpro2.glb');
  const { actions } = useAnimations(animations, groupRef);

  // Define materials



  const cartoonGreenMaterial = new MeshStandardMaterial({
    color: 0x99ff99, // Softer pastel green color

    emissive: 0x66ff66,
    emissiveIntensity: 0.3,
    roughness: 0.9,
    metalness: 0.1,
  });

  const cartoonRedMaterial = new MeshStandardMaterial({
    color: 0xff9999, // Softer pastel red color

    emissive: 0xff6666,
    emissiveIntensity: 0.6,
    roughness: 0.9,
    metalness: 0.1,
  });

  const [currentMaterial, setCurrentMaterial] = useState(cartoonGreenMaterial);




  const walking = 'Armature|mixamo.com|Layer0';
  const idle = 'Armature|mixamo.com|Layer0.001';
  const stop = 'Armature|mixamo.com|Layer0.002';

  const { level } = useUser(); // Access user level from context

  


  useEffect(() => {
    const loader = new TextureLoader();


    // Load the standard texture
    loader.load('purpletexw.png', (texture) => {
      console.log('Texture loaded:', texture);
      const maskMesh = nodes.masks; // Change 'masks' to the actual name of your mask mesh
      if (maskMesh) {
        // Define colors based on user level
        const colorMapping = {
          1: 0xB87333,    // Copper (Bronze-like)
          2: 0xC0C0C0,    // Silver
          3: 0xFFD700,    // Gold
          4: 0xE5E4E2,    // Platinum
          5: 0xB9F2FF,    // Diamond (Light Blue)
          6: 0xFFFFFF     // Pure White (Zen Master)
        };

        // Get the color based on the user level, default to gold if level not defined
        const maskColor = colorMapping[level.id] || 0xFFD700;

        // Create a new material with the texture and a base color
        const material = new THREE.MeshStandardMaterial({
          map: texture,
          color: maskColor,
          // transparent: true,
          opacity: 1.0,
          alphaMap: texture,
          metalness: 0.3,
          roughness: 0.2,
        });

        maskMesh.material = material;
        texture.flipY = false;
        maskMesh.material.needsUpdate = true;
      } else {
        console.error('Mask mesh not found');
      }
    });


    // Load the emission texture
    loader.load('greenem.png', (emissionTexture) => {
      emissionTexture.flipY = false;
      const maskMesh = nodes.masks; // Change 'masks' to the actual name of your mask mesh
      if (maskMesh) {
        // Change the material's emissive map to the new texture
        maskMesh.material.emissiveMap = emissionTexture;
        maskMesh.material.emissive = new THREE.Color(1, 1, 1); // Set emissive color (white in this case)
        maskMesh.material.needsUpdate = true; // Ensure the material updates

      }
    });

  }, [nodes, level],);

  useEffect(() => {
    console.log({ nodes })
    // Fade out current animation and fade in new animation
    if (isHolding) {
      if (hasMana) {
        // User is holding and has mana
        actions[walking].fadeOut(0.5);
        actions[stop].fadeOut(0.5);
        actions[idle].reset().fadeIn(0.5).play();
      } else {
        // User is holding but has no mana
        actions[idle].fadeOut(0.5);
        actions[stop].reset().fadeIn(0.5).play();
      }
    } else {
      // User is not holding, return to walking
      actions[idle].fadeOut(0.5);
      actions[stop].fadeOut(0.5);
      actions[walking].reset().fadeIn(0.5).play();
    }
  }, [isHolding, hasMana, actions, nodes]);

  // Handle click event
  const handlePointerDown = () => {
    if (!hasMana) {
      // Start fading to red material
      setCurrentMaterial(cartoonRedMaterial);
    }
  };

  useFrame((state) => {
    if (groupRef.current) {
      groupRef.current.rotation.y = Math.sin(state.clock.elapsedTime) * 0.3;
    }
  });

  return (
    <group ref={groupRef} {...props} dispose={null}>
      <group name="Scene">
        <group name="Armature" rotation={[Math.PI / 2, 0, 0]} scale={1}>
          <skinnedMesh
            name="body"
            geometry={nodes.body.geometry}
            material={currentMaterial}
            skeleton={nodes.body.skeleton}
            onPointerDown={handlePointerDown}
          />



          <primitive object={nodes.mixamorigHips} />
        </group>
      </group>
    </group>
  );
};

useGLTF.preload('/zenmodelpro2.glb');

export default ZenModel;
