import { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      errorCount: 0,
      lastErrorTime: null,
      retry: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('3D Rendering Error:', error);
    this.setState(prevState => {
      const now = Date.now();
      const timeSinceLastError = now - (prevState.lastErrorTime || 0);
      const shouldResetCount = timeSinceLastError > 60000; // Reset count after 1 minute

      return {
        errorCount: shouldResetCount ? 1 : prevState.errorCount + 1,
        lastErrorTime: now,
        retry: false // Reset retry flag
      };
    }, () => {
      // Check if we should reload or show an error message
      if (this.state.errorCount >= 3) {
        console.log('Multiple errors detected. Please refresh the page manually.');
      }
    });
  }

  handleRetry = () => {
    // Reset error state and attempt to re-render
    this.setState({ hasError: false, errorCount: 0, retry: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#1a1a1a',
          color: '#ffffff',
          zIndex: 9999
        }}>
          <h2 style={{ marginBottom: '1rem' }}>
            Rendering Error
          </h2>
          <p style={{ marginBottom: '2rem' }}>
            There was a problem loading the Models. Please try again.
          </p>
          <button onClick={this.handleRetry} style={{
            padding: '10px 20px',
            backgroundColor: '#00ffaa',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}>
            Retry
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;