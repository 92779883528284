import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firestore';
import { useUser } from '../context/userContext';
import Animate from '../Components/Animate';
import Spinner from '../Components/Spinner';

const Leaderboard = () => {
    const [users, setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(null); // Store current user data
    const [currentUserRank, setCurrentUserRank] = useState(null);
    const { id, loading } = useUser();

    useEffect(() => {
        const fetchUsers = async () => {
            const Id = id 
            try {
                const querySnapshot = await getDocs(collection(db, "telegramUsers"));
                const usersList = querySnapshot.docs.map(doc => doc.data());
                console.log('usersList:', usersList); // Debugging line
                usersList.sort((a, b) => b.balance - a.balance);
                console.log('sorted usersList:', usersList); // Debugging line
                setUsers(usersList.slice(0, 3));
                setAllUsers(usersList.slice(3, 20));

                // Find the current user's rank
                const userId = Id; // Replace with the actual user ID
                const currentUser = usersList.find(user => user.userId === userId);
                if (currentUser) {
                    const rank = usersList.indexOf(currentUser) + 1;
                    console.log('currentUser Rank:', rank); // Debugging line
                    setCurrentUser(currentUser); // Set current user data
                    setCurrentUserRank(rank);
                } else {
                    console.log('User  not found'); // Debugging line
                }
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };
        fetchUsers();
    }, [id]);

    const formatNumber = (number) => {
        if (number === undefined || number === null || isNaN(number)) {
            return '';
        }

        if (number >= 1000000) {
            return (number / 1000000).toFixed() + 'M';
        } else if (number >= 100000) {
            return (number / 1000).toFixed(0) + 'K';
        } else {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
    };

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (

                <Animate>

                    <div className='w-full relative h-screen'>
                        <div className='w-full flex justify-center flex-col space-y-3 pb-5'>
                            <h1 className="leaderboard-title">Leaderboard</h1>

                            <div className="leaderboard-top-users w-full  mt-[2px] px-5">
                                {users.slice(0, 3).map((user, index) => (
                                    <div key={index} className={`leaderboard-top-user ${index === 0 ? 'leaderboard-top-user-gold' : index === 1 ? 'leaderboard-top-user-silver' : 'leaderboard-top-user-bronze'}`}>
                                        <img src={index === 0 ? '/gold.webp' : index === 1 ? '/silver.webp' : '/bronze.webp'} alt="Medal" className="leaderboard-medal" />
                                        <p className="leaderboard-rank">#{index + 1}</p>
                                        <p className="leaderboard-username">{user.username}</p>
                                        <p className="leaderboard-balance">{formatNumber(user.balance)}</p>
                                    </div>
                                ))}
                            </div>

                        </div>

                        <div className='w-full '>
                            <h2 className=' font-bold ml-5 mb-5 text-xl'>Your Rank</h2>
                               {/* Current User Section */}
                               {currentUser && (
                                <div className="leaderboard-current-users w-full  mt-[2px] px-5  ">
                                    <div className="leaderboard-current-user bg-cards w-full border">
                                        <p className="leaderboard-username"> #{currentUserRank}</p>
                                        <p className="leaderboard-username">{currentUser.username}</p>
                                        <p className="leaderboard-username">{formatNumber(currentUser.balance)}</p>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className='w-full relative h-screen'>
                            <div id="refer" className='w-full h-screen homescreen mt-[2px] px-5'>
                                <div className='w-full flex flex-col scroller h-[80vh] overflow-y-auto pb-[250px]'>
                                    <div className="leaderboard-other-users">
                                        {allUsers.map((user, index) => (
                                            <div key={index} className="leaderboard-other-user">
                                                <div className="leaderboard-username">
                                                    <span> #{index + 4}</span>
                                                    <span className='font-bold'> {user.username} </span>
                                                </div>
                                                <div className="leaderboard-balance">{formatNumber(user.balance)}</div>
                                            </div>
                                        ))}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </Animate>
            )}
        </>


    );
};

export default Leaderboard;