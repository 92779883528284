import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Study = () => {
    const [email, setEmail] = useState('');
    const [isEmailRegistered, setIsEmailRegistered] = useState(false);
    const [message, setMessage] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleNotifyMe = () => {
        // Here you would typically handle the email submission logic
        console.log("Email submitted:", email);
        setIsEmailRegistered(true);
        setMessage("Thank you for signing up! We'll keep you updated.");
    };

    return (
        <div id="refer" className='w-full h-screen mt-[2px] px-5'>
            <div className='scroller h-[100vh] overflow-y-auto mt-5 pb-[150px]'>
                <div className="w-full flex items-center justify-center h-screen">
                    <div className="text-center text-white">
                        <h1 className="text-3xl font-bold mb-4">Coming Soon!</h1>
                        <p className="text-lg mb-8">Learn How to Make Money Online.</p>
                        <p className="text-lg mb-8">We're working hard to bring you the best resources.</p>
                        <div className="flex flex-col rounded justify-center mb-4">
                            <input
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                placeholder="Enter your email"
                                className="w-full h-[50px] text-black p-2 rounded focus:outline-none mb-5"
                            />
                            <button
                                onClick={handleNotifyMe}
                                className={`w-full h-[50px] bg-green-500 p-2 rounded mt-3 font-bold hover:bg-yellow-400 ${isEmailRegistered ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={isEmailRegistered}
                            >
                                {isEmailRegistered ? 'Exists' : 'Notify Me'}
                            </button>
                        </div>
                        {message && <p className="text-sm mt-4">{message}</p>}
                        <p className="text-sm">Stay tuned for updates!</p>

                        <Link
                            className='w-full bg-blue-500 mt-8 h-[50px] rounded flex items-center justify-center text-center text-white'
                            to="/tasks" // Set the destination path for more resources
                        >
                            Explore Resources
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Study;