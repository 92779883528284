//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAWnEZpQzbe9Ou_iVwbCWYhgYp8pJlGtYY",
  authDomain: "zeniverse-13026.firebaseapp.com",
  projectId: "zeniverse-13026",
  storageBucket: "zeniverse-13026.appspot.com",
  messagingSenderId: "564698220033",
  appId: "1:564698220033:web:a9d9d0b14a99e6d29ba200",
  measurementId: "G-6LNM57E3T2"
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
