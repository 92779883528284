import React, { useState, useEffect, useCallback } from 'react';
import { useCountdownTimer } from 'use-countdown-timer';
import { letters, countries, animals, names } from '../utils/data';
import { useUser  } from '../context/userContext'; // Add this import
import { db } from '../firebase/firestore';  // Add this import
import { doc, getDoc, setDoc } from 'firebase/firestore'; // Add this import
import { useNavigate } from 'react-router-dom';
const Container = () => {
	const navigate = useNavigate();
	const { id } = useUser (); // Add this line to get the user ID
	//const id = '905409744'; // Replace with any ID you want to use for testing
	const { countdown, reset, start } = useCountdownTimer({
		timer: 1000 * 40,
		autostart: false,
	});

	const [gameOver, setGameOver] = useState(false);
	const [score, setScore] = useState(0);
	const [finalScore, setFinalScore] = useState(0);
	const [bingoLetter, setBingoLetter] = useState(
		letters[Math.floor(Math.random() * letters.length)].toUpperCase()
	);

	const [country, setCountry] = useState('');
	const [name, setName] = useState('');
	const [animal, setAnimal] = useState('');
	const [showGameOverPopup, setShowGameOverPopup] = useState(false);

	const countriesUpper = countries.map(element => element.toUpperCase());
	const namesUpper = names.map(element => element.toUpperCase());
	const animalsUpper = animals.map(element => element.toUpperCase());

	// Memoize the isTimeUp function
	const isTimeUp = useCallback(() => countdown === 0, [countdown]);

	const clearFields = () => {
		setCountry('');
		setName('');
		setAnimal('');
	};

	const validateCountry = (country) => {
		return country.charAt(0).toUpperCase() === bingoLetter && countriesUpper.includes(country.toUpperCase());
	};

	const validateName = (name) => {
		return name.charAt(0).toUpperCase() === bingoLetter && namesUpper.includes(name.toUpperCase());
	};

	const validateAnimal = (animal) => {
		return animal.charAt(0).toUpperCase() === bingoLetter && animalsUpper.includes(animal.toUpperCase());
	};

	// Add this function to store the score in Firebase
	const storeScoreInFirebase = useCallback(async (score) => {
		if (!id) { // Ensure the user ID is available
			console.error('User  ID not available');
			return;
		}
	
		try {
			const userDoc = doc(db, 'telegramUsers', id); // Use the ID from user context
			const docSnapshot = await getDoc(userDoc); // Get the document snapshot
	
			let currentScore = 0;
			if (docSnapshot.exists()) {
				currentScore = docSnapshot.data().score || 0; // Get the existing score or default to 0
			}
	
			const newScore = currentScore + score; // Calculate the new score
	
			// Update the document with the new score
			await setDoc(userDoc, {
				score: newScore,
				timestamp: new Date(), // Optionally store the timestamp
			}, { merge: true }); // Use merge to update only the score field
	
			console.log('Score stored successfully');
		} catch (error) {
			console.error('Error storing score: ', error); // Log any errors
		}
	}, [id]); // Dependency on id

	useEffect(() => {
		if (isTimeUp()) {
			// Store the score when time is up
			storeScoreInFirebase(score); // Store the current score
			setFinalScore(score); // Set the final score before resetting
			setGameOver(true);
			clearFields();
			setShowGameOverPopup(true);
		}
	}, [isTimeUp, score, storeScoreInFirebase,]); // Include isTimeUp in dependencies
	

	const generateLetter = () => {
		setBingoLetter(
			letters[Math.floor(Math.random() * letters.length)].toUpperCase()
		);
	};

	const startGame = () => {
		setGameOver(false);
		generateLetter();
		setScore(0);
		reset();
		start();
		setShowGameOverPopup(false);
	};

	const bingo = () => {
		reset();
		if (country === '' || name === '' || animal === '') {
			alert('Please fill all fields');
			return;
		} else {
			let points = 0;

			if (validateCountry(country)) points += 10;
			if (validateName(name)) points += 10;
			if (validateAnimal(animal)) points += 10;

			if (points > 0) {
 setScore(prevScore => prevScore + points);
				clearFields();
				generateLetter();
				start();
			} else {
				setGameOver(true);
				clearFields();
				setFinalScore(score);
				storeScoreInFirebase(score); // Add this line to store the score
				setShowGameOverPopup(true);
			}
		}
	};

	const updateOverallScore = async () => {
		if (!id) {
		  console.error('User ID not available');
		  return;
		}
	  
		try {
		  const userRef = doc(db, 'telegramUsers', id);
	  
		  // Get the current overall score
		  const docSnapshot = await getDoc(userRef);
		  let currentOverallScore = 0;
		  if (docSnapshot.exists()) {
			currentOverallScore = docSnapshot.data().overallScore || 0;
		  }
	  
		  // Calculate new overall score
		  const newOverallScore = currentOverallScore + finalScore;
	  
		  // Update the document with the new overall score
		  await setDoc(userRef, {
			overallScore: newOverallScore,
			lastGameScore: finalScore,
			lastGameTimestamp: new Date(),
		  }, { merge: true });
	  
		  console.log('Overall score updated successfully');
		} catch (error) {
		  console.error('Error updating overall score: ', error);
		}
	  };

	const handleClosePopup = async () => {
		await updateOverallScore();
		navigate('/game');
		setShowGameOverPopup(false);
	  };

	return (
		
		<div className='bg-[#000)] px-10 py-8 w-full max-w-5xl drop-shadow-sm flex flex-col gap-8 text-white'>
			{/* Game Over Popup */}
			{showGameOverPopup && (
				<div className="absolute w-full h-[150px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-100 p-4 rounded-2xl text-accent flex flex-col items-center justify-center">
					<h1 className='text-center'>Game Over</h1>
					<p className='text-center'>Your Score: <span className='font-bold'>{finalScore}</span></p>
					<button className='mt-4 bg-cards text-white px-4 py-2 rounded' onClick={handleClosePopup}>
						Close
					</button>
				</div>
			)}
			{/* Rest of the code remains the same */}

			<div className='flex justify-between'>
				<p className='font-bold'>
					Timer:{' '}
					<span className={`${countdown <= 10000 ? 'text-red-500 animate-pulse' : ''}`}>
						{countdown / 1000} sec
					</span>
				</p>
				<p className='font-bold'>
					Score: <span>{score}</span>
				</p>
			</div>

			<div id="refer" className='w-full h-screen mt-[2px] px-5'>
			<div className='scroller h-[80vh] overflow-y-auto mt-5 pb-[150px]'>

			<div className='flex flex-col w-full mt-5 justify-center items-center'>
				<p>Bingo says letter</p>
				<h1 className='text-7xl font-bold m-5'>{bingoLetter}</h1>
			</div>
			<div className='flex flex-col lg:flex-row items-center justify-evenly gap-4'>
				<input
					type='text'
					value={country}
					onChange={(e) => {
						setCountry(e.target.value);
						start();
					}}
					placeholder='Country'
					className='outline-none bg-transparent border-b-2 border-accent-600 w-full h-[50px] mb-5 placeholder:text-gray-300'
				/>
				<input
					type='text'
					value={name}
					onChange={(e) => {
						setName(e.target.value);
						start();
					}}
					placeholder='Name'
					className='outline-none bg-transparent border-b-2 w-full border-accent-600 w-full h-[50px] mb-5  placeholder:text-gray-300'
				/>
				<input
					type='text'
					value={animal}
					onChange={(e) => {
						setAnimal(e.target.value);
						start();
					}}
					placeholder='Animal'
					className='outline-none bg-transparent border-b-2 w-full border-accent-600 w-full h-[50px] mb-1  placeholder:text-gray-300'
				/>
			</div>
			<div className='flex w-full items-center justify-center mt-4'>
				{!gameOver ? (
					<button
						className=' w-full bg-cards inline-block  px-6 py-3 rounded-[5px] font-bold text-white mx-auto'
						onClick={bingo}
						disabled={gameOver}
					>
						Bingo!
					</button>
				) : (
					<button
						className=' w-fullbg-gradient-to-r from-purple-600 to-pink-600 inline-block  px-6 py-3 rounded-2xl text-white mx-auto'
						onClick={startGame}
					>
						Start
					</button>
				)}
			</div>

			</div>
			</div>
		</div>
	);
};

export default Container;