import React from 'react';
import { isMobile } from 'react-device-detect';
import { ZenCoinMod } from './ZenCoinMod';


import { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { PerspectiveCamera } from '@react-three/drei';
import { OrbitControls } from '@react-three/drei';
import Spinner from '../Components/Spinner';


const DeviceCheck = ({ children }) => {
  if (!isMobile) {
    return <div className='w-full flex h-full justify-center px-5 items-center font-medium text-[20px]'>
        
       <div className='w-full pt-24 text-center flex flex-col space-y-3 justify-center items-center'>
        <p className='text-[28px] font-semibold pb-4 max-w-[20em]'>
            ZenEarn is only available on mobile device.
        </p>
        <div className="w-full h-full flex justify-center items-center ">
                <Canvas className="w-full h-full absolute inset-0" >
                  <OrbitControls enableDamping={true} dampingFactor={0.1} />
                  <PerspectiveCamera makeDefault position={[0, 0, 6]} />
                  <ambientLight intensity={1} />
                  <directionalLight position={[30, 10, 30]} intensity={3} />
                  <directionalLight position={[-20, -10, -20]} intensity={3} />
                  <Suspense fallback={<Spinner/>}>
                    < ZenCoinMod scale={0.3} position={[0, -2, 0]} rotation={[0, Math.sin(Date.now() * 5), 0]} />
                  </Suspense>
                </Canvas>
              </div>
       </div>
        
        </div>;
  }
  return <>{children}</>;
};

export default DeviceCheck;
