import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useUser } from '../context/userContext';
import Spinner from '../Components/Spinner';


const GameCard = ({ imageSrc, title, description, gamePath, cooldownTime, isCooldownActive, onPlayClick }) => (
    <article className="game-card flex flex-col bg-[#2d2d2d] items-center justify-center w-full">
        <img src={imageSrc} alt={description} width="100" height="100" />
        <h3 className="game-card-title">{title}</h3>
        <p className="game-card-description">{description}</p>

        <div className='game-play-button text-[#000] w-full h-[50px] font-bold bg-cards flex items-center justify-center'>
            {isCooldownActive ? `Cooldown: ${cooldownTime}` : 'Ready to Play!'}
        </div>

        <NavLink
            to={gamePath}
            className={`game-play-button text-[#000] w-full font-bold bg-cards flex items-center justify-center ${isCooldownActive ? 'opacity-50 cursor-not-allowed' : ''}`}
            style={{ height: '50px' }}
            onClick={(e) => {
                if (isCooldownActive) {
                    e.preventDefault();
                } else {
                    onPlayClick();
                }
            }}
        >
            {isCooldownActive ? 'Play (Cooldown)' : 'Play'}
        </NavLink>
    </article>
);


const MiniGame = () => {
    const location = useLocation();
    const { score, refreshScore, loading } = useUser();
    const secondsToDays = (seconds) => (seconds / 86400).toFixed(2); // Convert seconds to days

    const [games, setGames] = useState([
        {
            id: 'bingo',
            idpath: 'bingo',
            title: "Bingo Letters",
            description: "Make Words with the randomly generated Bingo Letters to earn Zen",
            imageSrc: "https://zenearn.netlify.app/zencoin.webp",
            cooldownDuration: 40, // 4 hours in seconds
            cooldown: false,
            remainingTime: 0,
        },
        {
            id: 'mystery',
            idpath: 'bingo',
            title: "Mystery Game",
            description: "Unlock this game to find out what rewards await you!",
            imageSrc: "https://storage.googleapis.com/a1aa/image/kKlgfwZXkwSITi7Ho2hJed3tfsyfLk6aphS12feLmP7LVz75E.jpg",
            cooldownDuration: 5184000, // 60 days (2 months)
            cooldown: true,
            remainingTime: 5184000, // Start with the full cooldown duration in days
        },
        {
            id: 'soon',
            idpath: 'bingo',
            title: "Coming Soon",
            description: "Stay tuned for more exciting games!",
            imageSrc: "https://placehold.co/100x100",
            cooldownDuration: 7776000, // 90 days (3 months)
            cooldown: true,
            remainingTime: 7776000, // Start with the full cooldown duration in days
        },
    ]);

    useEffect(() => {
        refreshScore();
        
        // Set up an interval to refresh the score every 30 seconds
        const intervalId = setInterval(refreshScore, 30000);
    
        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
      }, [refreshScore, location]);

    useEffect(() => {
        const storedCooldowns = JSON.parse(localStorage.getItem('gameCooldowns')) || {};
        const updatedGames = games.map(game => {
            const storedGame = storedCooldowns[game.id];
            if (storedGame) {
                const currentTime = Date.now();
                if (storedGame.endTime > currentTime) {
                    return {
                        ...game,
                        cooldown: true,
                        remainingTime: Math.floor((storedGame.endTime - currentTime) / 1000),
                    };
                }
            }
            return game;
        });
        setGames(updatedGames);
    }, [score, games]);


    useEffect(() => {
        let timer;
        const activeGames = games.filter(game => game.cooldown);
        if (activeGames.length > 0) {
            timer = setInterval(() => {
                setGames(prevGames => prevGames.map(game => {
                    if (game.cooldown && game.remainingTime > 0) {
                        if (game.remainingTime <= 1) {
                            // Reset cooldown
                            localStorage.setItem('gameCooldowns', JSON.stringify({
                                ...JSON.parse(localStorage.getItem('gameCooldowns')),
                                [game.id]: null,
                            }));
                            return { ...game, cooldown: false, remainingTime: 0 };
                        }
                        return { ...game, remainingTime: game.remainingTime - 1 };
                    }
                    return game;
                }));
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [games]);

    const startCooldown = (id) => {
        const updatedGames = games.map(game => {
            if (game.id === id) {
                const endTime = Date.now() + secondsToDays(game.cooldownDuration) * 1000; // Convert to seconds for calculation
                const newGame = { ...game, cooldown: true, remainingTime: game.cooldownDuration }; // Keep remaining time in days
                localStorage.setItem('gameCooldowns', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('gameCooldowns')),
                    [id]: { endTime },
                }));
                return newGame;
            }
            return game;
        });
        setGames(updatedGames);
    };

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    const formatNumber = (number) => {
        if (number === undefined || number === null || isNaN(number)) {
            return '';
        }

        if (number >= 1000000) {
            return (number / 1000000).toFixed() + 'M';
        } else if (number >= 100000) {
            return (number / 1000).toFixed(0) + 'K';
        } else {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
    };


    return (
        <>

            {loading ? (
                <Spinner />
            ) : (
                <section className="w-full pt-3 justify-center flex-col space-y-3 px-5">

                    <header className=" w-full flex justify-between ">
                        <div className='game-header'>
                            Mini Games
                        </div>

                        <div className='w-fit py-[2px] px-3 flex items-center space-x-1 justify-center border-[1px] border-[#707070] rounded-[5px]'>
                            <span className='w-[14px]'>
                                <img alt="engy" src='/loader.webp' className='w-full' />
                            </span>
                            <h1 className="text-[15px] font-bold">
                                {formatNumber(score)}
                            </h1>
                        </div>

                    </header>
                    <h2 className="game-subheader">Play to earn more Zen</h2>

                    <div id="refer" className='w-full h-screen mt-[2px] px-5'>
                        <div className='w-full flex flex-col scroller h-[80vh] overflow-y-auto mt-5 pb-[150px]'>
                            {games.map(game => (
                                <GameCard
                                    key={game.id}
                                    imageSrc={game.imageSrc}
                                    title={game.title}
                                    description={game.description}
                                    gamePath={`/${game.idpath}`}
                                    cooldownTime={formatTime(game.remainingTime)}
                                    isCooldownActive={game.cooldown}
                                    onPlayClick={() => startCooldown(game.id)}
                                />
                            ))}
                        </div>
                    </div>
                </section>
            )}

        </>
    );
};



export default MiniGame;