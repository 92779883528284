import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Ref from "./pages/Ref";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/Home";
import ErrorCom from "./Components/ErrorCom";
import Boost from "./pages/Boost";
import Wallet from "./pages/Wallet";
import TasksList from "./pages/Tasks";
import ReferralRewards from "./pages/Rewards";
import DeviceCheck from "./Components/DeviceCheck";
import Dashboard from "./pages/Dashboard";
import NotAdmin236 from "./pages/NotAdmin236";
import Settings from "./pages/Settings";
import EditTasks from "./pages/EditTasks";
import ExtrenalTasks from "./pages/ExtrenalTasks";
import Search from "./pages/Search";
import Statistics from "./pages/Statistics";
import { AuthContextProvider } from "./context/AuthContext";
import GoldHunters from "./pages/GoldHunters";
import MiningApp from "./pages/Mining";
import Leaderboard from "./pages/Leaderboard";
import MiniGame from "./pages/MiniGame";
import Container from "./Components/Container";
import ComingSoonStore from "./pages/Store";
import Study from "./pages/Study";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorCom />,
    children:[
      {
        path:"/",
        element: <GoldHunters />,
      },
      {
        path:"/ref",
        element: <Ref />,
      },
      {
        path:"/tasks",
        element: <TasksList />,
      },
      {
        path:"/boost",
        element: <Boost />,
      },
      {
        path:"/wallet",
        element: <Wallet />,
      },
      {
        path:"/game",
        element: <MiniGame /> ,
      },
      {
        path:"/bingo",
        element: <Container /> ,
      },
      {
        path:"/rewards",
        element: <ReferralRewards />,
      },
      {
        path:"/mining",
        element: <MiningApp /> ,
      },
      {
        path:"/leaderboard",
        element: <Leaderboard />,
      },
      {
        path:"/dashboardadmin36024x",
        element: <NotAdmin236 />,
      },
      {
        path:"/store",
        element: <ComingSoonStore />,
      },
      {
        path:"/study",
        element: <Study />,
      },
    ]

  },
  {
    path: "/dashboardZenX",
    element: <Dashboard />,
    errorElement: <ErrorCom />,
    children:[
      {
        path:"/dashboardZenX/settings",
        element: <Settings />,
      },
      {
        path:"/dashboardZenX/managetasks",
        element: <EditTasks />,
      },
      {
        path:"/dashboardZenX/externaltasks",
        element: <ExtrenalTasks />,
      },
      {
        path:"/dashboardZenX/search",
        element: <Search />,
      },
      {
        path:"/dashboardZenX/stats",
        element: <Statistics />,
      },

    ]
  }
]);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <DeviceCheck>
  <AuthContextProvider>
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
  </AuthContextProvider>
 </DeviceCheck>
);
