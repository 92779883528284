import React from 'react';

import AnimatedLogo from './AnimatedLogo';

const Spinner = () => {
  return (
    <div className="spinner-overlay">
    <div className="w-full fixed left-0 bg-[#000] right-0 top-0 bottom-0 flex flex-col items-center justify-center">
      <div className="flex flex-col items-center px-5 text-center text-white">
        <div>
          <AnimatedLogo  />
        </div>
        <div>
        <h1 className="text-xl font-bold mb-2">PLEASE WAIT</h1>

        <p className="text-sm">The Magical Journey Awaits</p>
        </div>
    
      </div>
    </div>
    </div>
  );
}

export default Spinner;