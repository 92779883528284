import { TextureLoader } from 'three';
import { useFrame } from '@react-three/fiber';
import { useRef, useMemo, useState, useEffect, useCallback } from 'react';

export function MovingBackground({ isHolding, hasMana }) {
    const [textures, setTextures] = useState([]);
    const [error, setError] = useState(false);
    const planes = useRef([]);

    // Wrap textureUrls in useMemo to prevent it from changing on every render
    const textureUrls = useMemo(() => ['/zenww.png', '/zenwr.png', '/zenrw.png'], []);

    const loadTextures = useCallback(async () => {
        const loadedTextures = await Promise.all(textureUrls.map(async (url) => {
            const cachedTexture = localStorage.getItem(url);
            if (cachedTexture) {
                const img = new Image();
                img.src = cachedTexture;
                return new Promise((resolve) => {
                    img.onload = () => resolve(new TextureLoader().load(img.src));
                });
            } else {
                return new Promise((resolve) => {
                    new TextureLoader().load(url, (texture) => {
                        localStorage.setItem(url, texture.image.src);
                        resolve(texture);
                    }, undefined, () => {
                        setError(true);
                    });
                });
            }
        }));

        return loadedTextures;
    }, [textureUrls]); // Keep textureUrls as a dependency

    useEffect(() => {
        loadTextures()
            .then((loadedTextures) => {
                if (loadedTextures.length === textureUrls.length) {
                    setTextures(loadedTextures);
                } else {
                    setError(true);
                }
            });
    }, [loadTextures, textureUrls]); // Include textureUrls as a dependency

    const selectedTextures = useMemo(() => {
        const numPlanes = 5;
        return textures.length ? Array.from({ length: numPlanes }, () => 
            textures[Math.floor(Math.random() * textures.length)]
        ) : [];
    }, [textures]);

    const updatePlanes = useCallback(() => {
        planes.current.forEach((plane) => {
            plane.position.z -= 0.02;
            if (plane.position.z < -20) {
                plane.position.z += planes.current.length * (7 - 0.01);
            }
        });
    }, []);

    useFrame(() => {
        if (!isHolding || !hasMana) {
            updatePlanes();
        }
    });

    if (error) {
        console.error("Error loading textures.");
        return null; // Avoid reloading the window
    }

    return (
        <>
            {selectedTextures.map((texture, index) => (
                <mesh
                    key={index}
                    ref={(el) => (planes.current[index] = el)}
                    position={[0, 1, index * (7 - 0.5)]}
                >
                    <planeGeometry args={[10, 10]} />
                    <meshBasicMaterial
                        attach="material"
                        map={texture}
                        transparent
                        opacity={1}
                    />
                </mesh>
            ))}
        </>
    );
}