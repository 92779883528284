import React, { useEffect, useState, useRef } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/firestore'; // Adjust the path as needed
import { Link } from 'react-router-dom';
import styled, { keyframes } from "styled-components";
import { PiHandTap } from 'react-icons/pi';
import { IoCheckmarkCircleSharp, IoClose } from "react-icons/io5";
import Animate from '../Components/Animate';
import Spinner from '../Components/Spinner';
import { RiArrowRightDoubleFill, RiSettings6Fill } from "react-icons/ri";
import { useUser } from '../context/userContext';
import Levels from '../Components/Levels';
import Exchanges from '../Components/Exchanges';
import { PiInfoFill } from "react-icons/pi";
import SettingsMenu from '../Components/SettingsMenu';
import Zwiper from '../Components/Zwiper';

import { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { PerspectiveCamera } from '@react-three/drei';
import ZenModel from "../Components/ZenModel.jsx";
import { MovingBackground } from '../Components/MovingBg.js';
import ErrorBoundary from '../Components/ErrorBoundary.js';



const userLevels = [
  { id: 1, name: 'Copper Calm', icon: '/bronze.webp', tapBalanceRequired: 1000 },
  { id: 2, name: 'Serene Silver', icon: '/silver.webp', tapBalanceRequired: 5000 },
  { id: 3, name: 'Golden Tranquility', icon: '/gold.webp', tapBalanceRequired: 10000 },
  { id: 4, name: 'Platinum Peace', icon: '/platinum.webp', tapBalanceRequired: 50000 },
  { id: 5, name: 'Radiant Diamond', icon: '/diamond.webp', tapBalanceRequired: 100000 },
  { id: 6, name: 'Zen Master', icon: '/master.webp', tapBalanceRequired: 1000000 },
];



const slideUp = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-350px);
  }
`;

const SlideUpText = styled.div`
  position: absolute;
  animation: ${slideUp} 3s ease-out;
  font-size: 2.1em;
  color: #ffffffa6;
  font-weight: 600;
  left: ${({ x }) => x}px;
  top: ${({ y }) => y}px;
  pointer-events: none; /* To prevent any interaction */
`;

const Container = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
`;




const GoldHunters = () => {
  const [isHolding, setIsHolding] = useState(false);


  const intervalRef = useRef(null);


  const [clicks, setClicks] = useState([]);
  const { score, balance, tapBalance, energy, battery, coolDownTime, selectedCharacter, fullName, setFullName, characterMenu, setCharacterMenu, setswiperCard, setSelectedCharacter, id, claimExchangePoint, setClaimExchangePoint, mainTap, selectedExchange, setEnergy, tapValue, setTapBalance, setBalance, refBonus, loading, initialized } = useUser();


  const [points, setPoints] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [isTimerVisible, setIsTimerVisible] = useState(false);
  const [openClaim, setOpenClaim] = useState(false);
  const exchangeRef = useRef(null);
  const [congrats, setCongrats] = useState(false)
  const [glowBooster, setGlowBooster] = useState(false);
  const [showLevel, setShowLevel] = useState(false);
  const [showExchange, setShowExchange] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [exchangePoints, setExchangePoints] = useState(0);
  // const [characterSelect, setCharacterSelect] = useState(true);
 // const [fullNameSelect, setFullNameSelect] = useState(false);
  //const [selectedCharacterOne, setSelectedCharacterOne] = useState({ name: '', avatar: '/boy.webp' });

  // const fullNameOn = toString(firstName + lastName)
  const [error, setError] = useState('');


   // Use a default character for all users
   const defaultCharacter = {
    name: 'Default',
    avatar: '/default.png' // Make sure this default avatar exists in your assets
  };


  const awardPoints = () => {
    const savedTime = localStorage.getItem('currentTime');
    if (savedTime) {
      const now = new Date();
      const savedDate = new Date(savedTime);

      const elapsedTime = (now - savedDate) / 3600000; // Time difference in seconds
      const pointsToAward = elapsedTime * 0.5; // Points for every 6 hours


      setExchangePoints(prevExchangePoints => prevExchangePoints + pointsToAward);

    }
  };


  const claimExchangePoints = async (event) => {

    if (exchangeRef.current && !exchangeRef.current.contains(event.target)) {

      const now = new Date();
      localStorage.setItem('currentTime', now.toISOString());
      const exchangeUpdated = Math.floor(exchangePoints); // Convert to integer
      const newBalance = balance + exchangeUpdated;
      const userRef = doc(db, 'telegramUsers', id.toString());
      try {
        await updateDoc(userRef, {
          balance: newBalance,
          tapBalance: tapBalance + exchangeUpdated
        });
        setBalance(newBalance)
        setTapBalance(tapBalance + exchangeUpdated)
        animateBalanceUpdate(newBalance); // Animate the balance update
        setClaimExchangePoint(false);
        setCongrats(true)

        setTimeout(() => {
          setCongrats(false)
        }, 4000)
      } catch (error) {
        console.error('Error updating balance exchanges', error);
      }
    }
  };

  const animateBalanceUpdate = (newBalance) => {
    const animationDuration = 300; // Animation duration in milliseconds
    const updateInterval = 20; // Update every 20 milliseconds
    const totalSteps = animationDuration / updateInterval;
    const increment = (newBalance - balance) / totalSteps; // Calculate increment per step
    let currentBalance = balance;
    let stepCount = 0;

    const intervalId = setInterval(() => {
      currentBalance += increment;
      stepCount += 1;
      if (stepCount >= totalSteps) {
        clearInterval(intervalId);
        currentBalance = newBalance;
      }
      setBalance(Math.floor(currentBalance.toFixed(0)));
    }, updateInterval);
  };


  const claimExchange = async () => {
    const now = new Date();
    localStorage.setItem('currentTime', now.toISOString());
    const exchangeUpdated = Math.floor(exchangePoints); // Convert to integer
    const newBalance = balance + exchangeUpdated;
    const userRef = doc(db, 'telegramUsers', id.toString());
    try {
      await updateDoc(userRef, {
        balance: newBalance,
        tapBalance: tapBalance + exchangeUpdated
      });
      setBalance(newBalance)
      setTapBalance(tapBalance + exchangeUpdated)
      animateBalanceUpdate(newBalance); // Animate the balance update
      setClaimExchangePoint(false);
      setCongrats(true)

      setTimeout(() => {
        setCongrats(false)
      }, 4000)
    } catch (error) {
      console.error('Error updating balance exchanges', error);
    }

  };


  useEffect(() => {
    awardPoints();
    const savedTime = localStorage.getItem('currentTime');
    console.log('Current time saved:', savedTime);
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {

    if (id) {
      if (claimExchangePoint) {
        document.addEventListener('mousedown', claimExchangePoints);
      } else {
        document.removeEventListener('mousedown', claimExchangePoints);
      }

      return () => {
        document.removeEventListener('mousedown', claimExchangePoints);
      };
    }
    // eslint-disable-next-line 
  }, [claimExchangePoint, id]);

  function triggerHapticFeedback() {
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    if (isIOS && window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
      console.log('Triggering iOS haptic feedback');
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    } else if (isAndroid) {
      console.log('Android device detected');
      if ('vibrate' in navigator) {
        console.log('Vibration API supported, triggering haptic feedback');
        navigator.vibrate(50); // Vibrate for 50ms
      } else {
        console.warn('Vibration API not supported on this Android device');
      }
    } else {
      console.warn('Haptic feedback not supported on this device');
    }
  }

  // Add this useEffect to prevent text selection
  useEffect(() => {
    const preventDefault = (e) => e.preventDefault();
    document.addEventListener('selectstart', preventDefault);
    document.addEventListener('contextmenu', preventDefault);
    return () => {
      document.removeEventListener('selectstart', preventDefault);
      document.removeEventListener('contextmenu', preventDefault);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  // ... other functions

  const handlePointerDown = (e) => {
    // Check if the event has preventDefault method before calling it
    if (e.preventDefault) {
      e.preventDefault(); // Prevent default behavior if applicable
    }

    // Prevent starting a new interval if one is already active
    if (intervalRef.current) {
      return; // Exit if an interval is already running
    }

    setIsHolding(true);
    triggerHapticFeedback();

    // Instead of calling handleClick immediately, set up a timeout
    const holdTimeout = setTimeout(() => {
      // After 2 seconds, start the interval
      intervalRef.current = setInterval(() => {
        // Check if mana is greater than 0 before calling handleClick
        if (energy > 0) { // Assuming 'energy' is a state or variable that holds the current mana level
          handleClick(e);
          console.log('2000ms');
        } else {
          // Clear the interval if mana is 0
          clearInterval(intervalRef.current);
          intervalRef.current = null; // Reset the interval reference
        }
      }, 900); // Changed to 2000ms for 2-second intervals
    }, 2000); // Wait 2 seconds before starting

    // Store the timeout so we can clear it if needed
    intervalRef.current = holdTimeout;
  };

  const handlePointerUp = () => {
    setIsHolding(false);
    if (intervalRef.current) {
      clearTimeout(intervalRef.current); // Clear timeout if it hasn't triggered yet
      clearInterval(intervalRef.current); // Clear interval if it's running
      intervalRef.current = null; // Reset the ref to prevent multiple intervals
    }
  };

  const handleClick = (e) => {
    triggerHapticFeedback();
    if (energy <= 0 || isDisabled) {
      setGlowBooster(true); // Trigger glow effect if energy and points are 0
      isTimerVisible(true)
      setTimeout(() => {
        setGlowBooster(false); // Remove glow effect after 1 second
      }, 300);
      return; // Exit if no energy left or if clicks are disabled
    }

    // Increment the count
    const newClick = {
      id: Date.now(),
      x: 0, // You might need to calculate these differently for 3D
      y: 0, // You might need to calculate these differently for 3D
    };



    setClicks((prevClicks) => [...prevClicks, newClick]);
    setEnergy((prevEnergy) => {
      const newEnergy = prevEnergy - tapValue.value;
      if (newEnergy <= 0) {
        if (!isDisabled) {
          // Set a timer for 12 hours
          const endTime = new Date(new Date().getTime() + coolDownTime);
          localStorage.setItem('endTime', endTime);
          localStorage.setItem('energy', newEnergy);
          setIsDisabled(true);
          const timer = setInterval(() => {
            const newTimeLeft = new Date(endTime) - new Date();
            if (newTimeLeft <= 0) {
              clearInterval(timer);
              localStorage.removeItem('endTime');
              setIsDisabled(false);
              setIsTimerVisible(false);
              setEnergy(battery.energy);
            } else {
              setTimeRemaining(newTimeLeft);
            }
          }, 1000);
        }
        return 0; // Ensure energy does not drop below 0
      }
      return Math.max(newEnergy, 0); // Ensure energy does not drop below 0
    });
    setPoints(prevPoints => {
      const newPoints = prevPoints + tapValue.value;
      if (newPoints === 50 || newPoints === 80 || newPoints === 150) {
        const tapId = newPoints === 50 ? "tapmore" : newPoints === 80 ? "tapmore2" : "tapmore3";
        const taps = document.getElementById(tapId);
        if (taps) {
          taps.style.display = "block";
          setTimeout(() => { taps.style.display = "none"; }, 2000);
        }
      }
      return newPoints;
    });
    // Remove the click after the animation duration
    setTimeout(() => {
      setClicks((prevClicks) =>
        prevClicks.filter((click) => click.id !== newClick.id)
      );
    }, 1000); // Match this duration with the animation duration
  };

  const handleClaim = async () => {
    const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
    if (telegramUser) {
      const { id: userId } = telegramUser;
      const userRef = doc(db, 'telegramUsers', userId.toString());
      try {
        await updateDoc(userRef, {
          balance: balance + points,
          energy: energy,
          tapBalance: tapBalance + points

        });
        setBalance((prevBalance) => prevBalance + points);
        setTapBalance((prevTapBalance) => prevTapBalance + points);
        localStorage.setItem('energy', energy);

        if (energy <= 0) {
          setIsTimerVisible(true);
        }
        console.log('Points claimed successfully');
      } catch (error) {
        console.error('Error updating balance and energy:', error);
      }
    }
    openClaimer();
  };



  useEffect(() => {
    const savedEndTime = localStorage.getItem('endTime');
    if (savedEndTime) {
      const endTime = new Date(savedEndTime);
      const newTimeLeft = endTime - new Date();
      if (newTimeLeft > 0) {
        setIsDisabled(true);
        setIsTimerVisible(true);
        setTimeRemaining(newTimeLeft);
        const timer = setInterval(() => {
          const updatedTimeLeft = endTime - new Date();
          if (updatedTimeLeft <= 0) {
            clearInterval(timer);
            localStorage.removeItem('endTime');
            setIsDisabled(false);
            setIsTimerVisible(false);
            setEnergy(battery.energy);
          } else {
            setTimeRemaining(updatedTimeLeft);
          }
        }, 1000);
      } else {
        localStorage.removeItem('endTime');
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialized) {
      const savedEnergy = localStorage.getItem('energy');
      console.log("Energy Remaining:", savedEnergy);
      console.log("Time remaining", timeRemaining)
    }
  }, [timeRemaining, energy, initialized]);

  const closeClaimer = () => {
    setOpenClaim(false);
    setPoints(0); // Reset points after claiming

  };

  const openClaimer = () => {
    setOpenClaim(true)
    setCongrats(true)

    setTimeout(() => {
      setCongrats(false)
    }, 4000)


  }



  const formatTimeRemaining = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const formatNumber = (num) => {
    if (num < 100000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else if (num < 1000000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else {
      return (num / 1000000).toFixed(3).replace(".", ".") + " M";
    }
  };

  // const clearTimeRemaining = () => {
  //   localStorage.removeItem('endTime');
  //   setTimeRemaining(null);
  //   setIsDisabled(false);
  //   setIsTimerVisible(false);
  // };



  const initialLevelIndex = userLevels.findIndex(level => tapBalance < level.tapBalanceRequired);
  const currentLevelIndex = initialLevelIndex === -1 ? userLevels.length - 1 : initialLevelIndex;

  const displayedLevelIndex = currentLevelIndex
  const currentLevel = userLevels[displayedLevelIndex];





  const handleSaveFullName = async (newBalance) => {


    // Check if the fullName field is empty
    if (!fullName.trim()) {
      setError('Enter a name to proceed');
      return;
    }

   

    if (id) {
      try {
        // Update the Firestore document
        newBalance = balance + 50;
        const userRef = doc(db, 'telegramUsers', id.toString());
        await updateDoc(userRef, {
          fullName: fullName,
          character: defaultCharacter,
          balance: newBalance,
          tapBalance: newBalance,
        });

        setCharacterMenu(false);
        setswiperCard(false);
        animateBalanceUpdate(newBalance); // Animate the balance update
        setBalance(newBalance);
        setTapBalance(newBalance);
        setSelectedCharacter(defaultCharacter);
        setCongrats(true);

        setTimeout(() => {
          setCongrats(false);
        }, 4000);

        // Clear the error message if the update is successful
        setError('');
      } catch (error) {
        console.error('Error updating document:', error);
        setError('Failed to update user information. Please try again.');
      }
    }
    console.log('Character Image is:', defaultCharacter);

  };

  useEffect(() => {
    console.log('CHARACTER IS:', defaultCharacter);

  })


  return (
    <>
      {loading ? (
        <Spinner />
      ) : (

        <Animate>

          {<Zwiper />}

          <div className='w-full flex justify-center flex-col space-y-3'>



            <div className='w-full flex justify-between items-center px-4 z-10 pb-[2px] pt-1'>

              <div className='w-full flex items-center space-x-[1px] px-1'>
                <div className='w-[30px] h-[30px] bg-cards rounded-[5px] p-[6px] overflow-hidden flex items-center justify-center relative'>
                  <img src={defaultCharacter.avatar} className='w-[25px] mt-[6px]' alt={fullName || "user"} />

                </div>

                <h1 className='text-[11px] px-2 font-semibold'>
                  {fullName}
                </h1>

              </div>

              <div className='w-[60%] flex flex-col space-y-1 pr-4'>

                <div className='w-full flex justify-between text-[10px] font-medium items-center'>

                  <span className='levelName flex items-center'>
                    <span onClick={() => setShowLevel(true)} className=''>{currentLevel.name}</span>
                    <span className='flex items-center'>  <RiArrowRightDoubleFill size={12} className='mt-[1px]' /> </span>
                  </span>

                  <span className=''>

                    {currentLevel.id}/{userLevels.length}


                  </span>
                </div>

                <div className='flex w-full mt-2 items-center bg-[#56565630] rounded-[10px] border-[1px] border-[#49494952]'>


                  <div className={`h-[8px] rounded-[2px] levelbar`} style={{ width: `${(tapBalance / currentLevel.tapBalanceRequired) * 100}%` }} />
                </div>

              </div>

            </div>

            <div className='w-full flex justify-between items-center px-4 z-10 pb-[2px] pt-1'>



              <div className='flex w-full bg-[#5c5c5c52] border-[1px] border-[#434343] h-[40px] mb-[-4px] py-3 px-3 rounded-[10] items-center'>


                <button onClick={() => setShowExchange(true)} className=''>
                  <img id={selectedExchange.id} src='/zencoin.webp' alt={selectedExchange.name} className={`w-[22px]`} />
                </button>

                <div className='w-[1px] h-[18px] mx-[10px] bg-divider2' />
                <div className='flex flex-1 flex-col space-y-1 items-center justify-center'>
                  <p className='text-[9px]'>
                    Profit per hour
                  </p>
                  <div className='flex items-center justify-center space-x-1 text-[11px]'>

                    <span className='flex items-center justify-center'>
                      <img src='/coin.webp' alt='ppf' className='w-[12px]' />
                    </span>
                    <span className='font-bold'>
                      +{tapValue.value}
                    </span>
                    <span className='flex items-center justify-center'>
                      <PiInfoFill size={14} className='text-info' />
                    </span>

                  </div>

                </div>

                <div className='w-[1px] h-[18px] mx-[10px] bg-divider2' />

                <button onClick={() => setShowSetting(true)} className=''>
                  <RiSettings6Fill size={20} className='' />
                </button>

              </div>


            </div>


            <div className='w-full relative h-screen bg-divider '>
              <div id="refer" className='w-full h-screen homescreen  mt-[2px] px-5'>



                <div className='w-full flex flex-col scroller h-[80vh] overflow-y-auto pb-[150px]'>



                  <div className='w-full flex items-center justify-center space-x-5 py-6'>
                    <div className='bg-cards3 text-[#dddddd] py-[15px] px-5 w-[45%] flex justify-center space-x-1 items-center rounded-[6px] text-[15px] font-medium'>
                      <span className='text-[16px]'>
                        <PiHandTap size={18} className='tesxt-[#228B22] text-btn4' />
                      </span>
                      {isTimerVisible ? (

                        <span className='text-nowrap'>{`${formatTimeRemaining(timeRemaining)}`}</span>

                      ) : (
                        <span className='text-nowrap'>
                          {energy} mana left</span>
                      )}
                    </div>
                    <Link
                      to="/boost"
                      className='bg-btn4 getBoosters text-[#000] py-[14px] px-5 text-nowrap rounded-[5px] font-bold text-[15px]'
                    >
                      Enhancements
                    </Link>
                  </div>
                  <h1 className='flex w-full justify-center items-center space-x-1 pb-2'>
                    <img src='/loader.webp' className='w-[30px]' alt='engagecoin' />
                    <span className='text-[#fff] text-[32px] font-bold'>
                      <span className='pl-[2px]'>{formatNumber(balance + refBonus + score)} <span className='text-btn4'>ZEN</span></span>
                    </span>
                  </h1>
                  <div>


                  </div>






                  <div className='w-full flex justify-center items-center  clickContainer'>

                    <div className="w-full relative flex items-center justify-center">


                      {mainTap && (
                        <Container className=' flex justify-center items-center clickContainer'>
                          <div className="w-full h-[50vh] relative flex items-center justify-center">

                            <ErrorBoundary fallback={<div>Error loading 3D model</div>}>
                              <Canvas className="canvas-background">
                                <Suspense fallback={<Spinner />}>
                                  <PerspectiveCamera makeDefault position={[0, 0, 5]} />

                                  {/* Increased ambient light for better overall illumination */}
                                  <ambientLight intensity={0.3} />

                                  {/* Hemispheric light for a more natural look */}
                                  <hemisphereLight
                                    skyColor={0x87ceeb} // Light blue color for the sky
                                    groundColor={0x444444} // Dark gray for the ground
                                    intensity={0.5} // Adjusted intensity for a softer effect
                                  />

                                  {/* Directional lights for focused light */}
                                  <directionalLight
                                    position={[30, 20, 30]} // Higher position for better highlights
                                    intensity={0.6} // Adjusted intensity for balanced lighting
                                    castShadow
                                    shadow-mapSize-width={2048} // Higher resolution shadows
                                    shadow-mapSize-height={2048}
                                    shadow-camera-near={0.5}
                                    shadow-camera-far={50}
                                    shadow-camera-left={-10}
                                    shadow-camera-right={10}
                                    shadow-camera-top={10}
                                    shadow-camera-bottom={-10}
                                  />
                                  <directionalLight
                                    position={[-30, 10, -30]} // Adjusted position for more dynamic lighting
                                    intensity={0.4} // Softer light for gentle shadows
                                    castShadow
                                  />

                                  {/* Point light for sparkle effect */}
                                  <pointLight
                                    position={[0, 5, 0]} // Position it above the model
                                    intensity={1.0} // Adjusted brightness
                                    distance={15} // Increased distance for a wider reach
                                    decay={2} // How quickly the light fades
                                    color={0xffffff} // White light color
                                  />

                                  {/* Spotlight for focused lighting */}
                                  <spotLight
                                    position={[0, 10, 5]} // Position it above and in front of the model
                                    angle={0.4} // Wider angle for a softer focus
                                    penumbra={0.3} // Softer edges
                                    intensity={1.2} // Increased brightness
                                    castShadow
                                    shadow-mapSize-width={1024}
                                    shadow-mapSize-height={1024}
                                  />

                                  <ZenModel
                                    scale={7}
                                    position={[0, -2, 0]}
                                    isHolding={isHolding}
                                    hasMana={energy > 0}
                                    onPointerDown={handlePointerDown}
                                    onPointerUp={handlePointerUp}
                                    onPointerLeave={handlePointerUp}
                                  />

                                  <MovingBackground
                                    isHolding={isHolding}
                                    hasMana={energy > 0}
                                    style={{ pointerEvents: 'none' }}
                                  />
                                </Suspense>
                              </Canvas>
                            </ErrorBoundary>
                          </div>

                          {clicks.map((click) => (
                            <SlideUpText key={click.id} x={click.x} y={click.y}>
                              +{tapValue.value}
                            </SlideUpText>
                          ))}

                          <span id="tapmore" className='bg-[#333333b0] hidden tapmore p-[6px] rounded-[6px] absolute top-0 right-0'>
                            Keep going!!
                          </span>
                          <span id="tapmore2" className='bg-[#333333b0] hidden tapmore p-[6px] rounded-[6px] absolute top-0 left-0'>
                            Don't give up!
                          </span>
                          <span id="tapmore3" className='bg-[#333333b0] hidden tapmore p-[6px] rounded-[6px] absolute top-[-10px] left-[30%]'>
                            You can do it!!!
                          </span>

                          {/* New Divs for Links */}
                          <div className='absolute top-0 left-0 flex flex-col items-start'>
                            <Link to="/wallet" className='w-[60px] h-[60px] flex flex-col justify-center items-center mb-10'>
                              <img src='/gwallet.png' className='' alt='engagecoin' />
                              <p className='font-bold text-[15px]'>Wallet</p>
                            </Link>
                            <Link to="/game" className='w-[60px] h-[60px] flex flex-col justify-center items-center mb-5'>
                              <img src='/game.png' className='' alt='engagecoin' />
                              <p className='font-bold text-[15px]'>Games</p>
                            </Link>

                          </div>

                          <div className='absolute top-0  right-0 flex flex-col items-end'>
                            <Link to="/leaderboard" className='w-[60px] h-[60px]flex flex-col justify-center items-center mb-5'>
                              <img src='/gcrown.png' className='' alt='engagecoin' />
                              <p className='font-bold text-[15px]'>Rank</p>
                            </Link>

                            <Link to="/study" className='w-[60px] h-[60px] flex flex-col justify-center items-center mb-5'>
                              <img src='/book.png' className='' alt='engagecoin' />
                              <p className='font-bold text-[15px]'>Study</p>
                            </Link>

                          </div>
                        </Container>
                      )}



                    </div>
                  </div>



                  <div className='w-full  py-6 flex justify-center'>
                    <div className={`${glowBooster === true ? "glowbutton" : ""} w-full flex justify-between items-center bg-cards3 rounded-[5px] py-3 px-4`}>

                      {energy === 0 && points === 0 ? (
                        <>
                          <Link
                            to="/boost"
                            className='w-full flex justify-center items-center font-bold text-[15px]'
                          >
                            Get More Mana
                          </Link>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={handleClaim}
                            disabled={points === 0}
                            className={`${points === 0 || openClaim ? 'text-[#ffffff71] bg-btn2' : 'bg-btn4 text-[#000]'} py-[14px] px-8 rounded-[12px] font-bold text-[16px]`}
                          >
                            Redeem
                          </button>

                          <span className='text-[#fff] font-semibold text-[24px]'>
                            <span className='pl-[2px]'>{points} <span className='text-btn4'>ZEN</span></span>
                          </span>

                        </>
                      )}

                    </div>
                  </div>

                </div>
              </div>

              {/* Claim Modal */}
              <div className='w-full absolute top-[50px] flex justify-center z-50 pointer-events-none select-none'>
                {congrats ? (<img src='/congrats.gif' alt="congrats" className="w-[80%]" />) : (<></>)}
              </div>



              <div
                className={`${openClaim === true ? "visible" : "invisible"
                  } fixed top-[-12px] claimdiv bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#00000042] flex flex-col justify-center items-center px-4`}
              >

                <div className={`${openClaim === true ? "opacity-100 mt-0" : "opacity-0 mt-[100px]"
                  } w-full bg-modal rounded-[16px] relative flex flex-col ease-in duration-300 transition-all justify-center p-8`}>


                  <div className="w-full flex justify-center flex-col items-center space-y-3">
                    <div className="w-full items-center justify-center flex flex-col space-y-2">
                      <IoCheckmarkCircleSharp size={32} className='text-accent' />
                      <p className='font-medium'>Breath!!!</p>
                    </div>
                    <h3 className="font-medium text-[24px] text-[#ffffff] pt-2 pb-2">
                      <span className='text-accent'>+{points}</span> ZEN
                    </h3>
                    <p className="pb-6 text-[#bfbfbf] text-[15px] w-full text-center">
                      Keep calm! breath! and  Get more ZEN!
                    </p>

                    <div className="w-full flex justify-center">
                      <button
                        onClick={closeClaimer}
                        className="bg-btn4 w-fit py-[10px] px-6 flex items-center justify-center text-center rounded-[12px] font-medium text-[16px]"
                      >
                        Earn More!
                      </button>
                    </div>
                  </div>
                </div>

              </div>




              {selectedExchange.id === 'selectex' || exchangePoints < 5 ? (
                <>
                </>
              ) : (
                <div className={`${claimExchangePoint ? 'flex' : 'hidden'} fixed bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#303030c4] flex-col justify-end items-center`}>


                  <div ref={exchangeRef} className={`w-full bg-divider shadowtop relative flex flex-col ease-in duration-300 transition-all justify-center`}>


                    <div className="w-full flex bg-[#202020]  mt-[2px] justify-center relative flex-col items-center space-y-3 p-4 pt-20 pb-10">

                      <button
                        onClick={claimExchange}
                        className="flex items-center justify-center h-[32px] w-[32px] rounded-full bg-[#383838] absolute right-6 top-4 text-center font-medium text-[16px]"
                      >
                        <IoClose size={20} className="text-[#9995a4]" />
                      </button>

                      <div className='w-full bg-cards rounded-[16px] relative px-4 flex flex-col justify-center items-center'>

                        <div className='w-full items-center flex pt-1 justify-center space-x-[6px]'>
                          <img src='/zencoin.webp' alt='coin' className='w-[36px]' />
                          <h3 className="font-bold text-[36px] text-[#ffffff] pt-2 pb-3 mt-[4px]">
                            <span className='text-cardtext'>{formatNumber(exchangePoints.toFixed(0))}</span>
                          </h3>
                        </div>

                        <p className="pb-6 text-[#fff] font-semibold px-8 text-[17px] w-full text-center">
                          {selectedExchange.name} has rewarded your effort
                        </p>
                      </div>
                      <div className="w-full flex justify-center pb-7">
                        <button
                          onClick={claimExchange}
                          className="bg-btn4 w-full py-[18px] px-6 text-nowrap flex items-center justify-center text-center rounded-[12px] font-semibold text-[17px]"
                        >
                          Redeem Reward
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}


              {characterMenu === true && selectedCharacter.name === '' && (
             <div style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              height: '100vh',
              zIndex: 40,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(20px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <div style={{
                width: '90%',
                maxWidth: '400px',
                backgroundColor: '#1E1E1E',
                borderRadius: '20px',
                boxShadow: '0 10px 25px rgba(0, 0, 0, 0.2)',
                padding: '30px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center'
              }}>
                <h1 style={{
                  fontSize: '24px',
                  fontWeight: '600',
                  color: '#FFFFFF',
                  marginBottom: '20px'
                }}>
                  Welcome Zenite
                </h1>
                <p style={{
                  fontSize: '16px',
                  color: '#B0B0B0',
                  marginBottom: '30px'
                }}>
                  Explore, earn and learn your way to Zen master
                </p>
                <h2 style={{
                  fontSize: '18px',
                  fontWeight: '500',
                  color: '#4CAF50',
                  marginBottom: '20px'
                }}>
                  Add your name to proceed
                </h2>
                <div style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  {error && <p style={{ color: '#FF6B6B', marginBottom: '10px' }}>{error}</p>}
                  <input
                    type="text"
                    placeholder="Enter your name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    style={{
                      width: '100%',
                      padding: '15px',
                      borderRadius: '10px',
                      backgroundColor: '#2C2C2C',
                      border: 'none',
                      color: '#FFFFFF',
                      fontSize: '16px',
                      textAlign: 'center',
                      marginBottom: '20px'
                    }}
                  />
                  <button
                    onClick={handleSaveFullName}
                    style={{
                      width: '100%',
                      padding: '15px',
                      borderRadius: '10px',
                      backgroundColor: '#4CAF50',
                      color: '#FFFFFF',
                      fontSize: '16px',
                      fontWeight: '600',
                      border: 'none',
                      cursor: 'pointer',
                      transition: 'background-color 0.3s'
                    }}
                    onMouseOver={(e) => e.target.style.backgroundColor = '#45a049'}
                    onMouseOut={(e) => e.target.style.backgroundColor = '#4CAF50'}
                  >
                    Join Zeniverse
                  </button>
                </div>
              </div>
            </div>
              )}


            </div>
            <Levels showLevel={showLevel} setShowLevel={setShowLevel} />
            <Exchanges showExchange={showExchange} setShowExchange={setShowExchange} />
            <SettingsMenu showSetting={showSetting} setShowSetting={setShowSetting} />

          </div>

        </Animate>
      )}
    </>
  );
};

export default GoldHunters;