// ZenMasks.jsx
import React, { useRef, useEffect } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { TextureLoader } from 'three';

export function ZenMasks({ level, ...props }) {
    const { nodes, materials } = useGLTF('/masks.glb')
    const meshRef = useRef();
    const materialRef = useRef();

    // Define color mapping
    const colorMapping = {
        'Copper Calm': 0xB87333,    // Copper (Bronze-like)
        'Serene Silver': 0xC0C0C0,    // Silver
        'Golden Tranquility': 0xFFD700,    // Gold
        'Platinum Peace': 0xE5E4E2,    // Platinum
        'Radiant Diamond': 0xB9F2FF,    // Diamond (Light Blue)
        'Zen Master': 0xFFFFFF     // Pure White (Zen Master)
    };

    useEffect(() => {
        const loader = new TextureLoader();

        // Load the texture
        loader.load('/purpletexw.png', (texture) => {
            console.log('Texture loaded:', texture);
            
            // Get the color based on the level name, default to gold if level not defined
            const maskColor = colorMapping[level?.name] || 0xB87333;

            // Create a new material with the texture and color
            const material = new THREE.MeshStandardMaterial({
                map: texture,
                color: maskColor,
                opacity: 1.0,
                alphaMap: texture,
                metalness: 0.3,
                roughness: 0.2,
            });

            texture.flipY = false;
            materialRef.current = material;
            
            // Force a material update
            if (meshRef.current) {
                meshRef.current.material = material;
                meshRef.current.material.needsUpdate = true;
            }
        });

          // Load the emission texture
    loader.load('greenem.png', (emissionTexture) => {
        emissionTexture.flipY = false;
        const maskMesh = nodes.masks; // Change 'masks' to the actual name of your mask mesh
        if (maskMesh) {
          // Change the material's emissive map to the new texture
          maskMesh.material.emissiveMap = emissionTexture;
          maskMesh.material.emissive = new THREE.Color(1, 1, 1); // Set emissive color (white in this case)
          maskMesh.material.needsUpdate = true; // Ensure the material updates
  
        }
      });
    }, [level]); // Re-run when level changes



    useFrame((state) => {
        if (meshRef.current) {
            meshRef.current.rotation.z = Math.sin(state.clock.elapsedTime) * 0.2;
        }
    });

    return (
        <group {...props} dispose={null}>
            <mesh
                ref={meshRef}
                castShadow
                receiveShadow
                geometry={nodes.маска.geometry}
                material={materialRef.current || materials['1']}
            />
        </group>
    )
}

useGLTF.preload('/masks.glb')