import React, { useEffect, useState } from 'react';
import { db } from '../firebase/firestore'; // Ensure you have your Firebase config set up
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { Address } from './WalletDetails';
import { useUser } from '../context/userContext';
import Animate from '../Components/Animate';


const VotingCards = () => {
  const { id } = useUser();
  // const id = '905409744'; // Replace this with the actual user ID from context if needed
  const [votes, setVotes] = useState([0, 0, 0]); // Total votes for each card
  const [hasVoted, setHasVoted] = useState([false, false, false]);

  const cards = [
    {
      title: 'MARKETPLACE',
      description: 'An Ecosystem where you can buy items from Zen Store',
    },
    {
      title: 'ZEN TOKEN',
      description: 'Conventing Zen points to $Zen Token or Ton',
    },
    {
      title: 'ZEN TO FIAT',
      description: 'Converting Zen points to desired currencies',
    },
  ];

  useEffect(() => {
    const fetchVotes = async () => {
      const docRef = doc(db, 'votes', 'votingData');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log('Fetched data:', data); // Log fetched data
        setVotes(data.votes || [0, 0, 0]);
        const userVotes = data.userVotes || {};
        const userHasVoted = cards.map((_, index) => userVotes[id]?.includes(index) || false);
        setHasVoted(userHasVoted);
      } else {
        console.log('No such document! Initializing voting data...');
        // Initialize the document with default values
        await setDoc(docRef, {
          votes: [0, 0, 0], // Initialize votes for each card
          userVotes: {}, // Initialize user votes
        });
        console.log('Voting data initialized.');
      }
    };

    fetchVotes();
  }, []); // Empty dependency array to run only once on mount

  const handleVote = async (index) => {
    if (hasVoted[index]) return; // Prevent voting if already voted

    // Update total votes
    const newVotes = [...votes];
    newVotes[index] += 1; // Increment the votes for the selected card

    // Update Firestore
    const docRef = doc(db, 'votes', 'votingData');
    const docSnap = await getDoc(docRef);
    const userVotes = { ...(docSnap.data().userVotes || {}), [id]: [...(docSnap.data().userVotes[id] || []), index] };

    await setDoc(docRef, {
      votes: newVotes,
      userVotes: userVotes, // Store user votes
    });

    setVotes(newVotes);
    setHasVoted((prev) => {
      const newHasVoted = [...prev];
      newHasVoted[index] = true; // Mark this card as voted
      return newHasVoted;
    });
  };

  // Function to format numbers
  const formatNumber = (number) => {
    return new Intl.NumberFormat().format(number);
  };

  return (
   
          <Animate>
            <div id="refer" className='w-full  h-[70vh] homescreen mt-[2px] px-5'>
              <div className='w-full flex flex-col scroller h-[70vh] overflow-y-auto pb-[250px] '>

                {cards.map((card, index) => (
                  <div
                    key={index}
                    className="w-full bg-white rounded-lg shadow-lg p-4 mb-5 flex flex-col justify-between items-center h-50"
                  >
                    <h2 className="text-xl text-[#000] font-bold">{card.title}</h2>
                    <p className="text-gray-600">{card.description}</p>
                    <div className="w-full flex flex-col items-center mt-4"> {/* Changed to flex-col to center button and text */}
                      <button
                        onClick={() => handleVote(index)}
                        disabled={hasVoted[index]} // Disable button if already voted
                        className={`w-full bg-cards text-white py-2 px-4 rounded hover:bg-cards3 ${hasVoted[index] ? 'opacity-50 cursor-not-allowed' : ''}`}
                      >
                        {hasVoted[index] ? formatNumber(votes[index])  : 'vote'} {/* Show formatted vote count if voted, otherwise show "Like" */}
                      </button>

                    </div>
                  </div>

                ))}


                <Address />
              </div>
            </div>
          </Animate>
   
  );


};

export default VotingCards;