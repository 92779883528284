import React, { useEffect, useRef } from "react";
import Animate from "../Components/Animate";
import { Outlet } from "react-router-dom";
import { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { PerspectiveCamera } from '@react-three/drei';
import { OrbitControls } from '@react-three/drei';
import Spinner from '../Components/Spinner';


import { useUser } from "../context/userContext";
import { ZenCoinMod } from "../Components/ZenCoinMod.jsx";
import VotingCards from "../Components/Votingcards.js";



const Wallet = () => {

  const { openInfoTwo, setOpenInfoTwo, loading } = useUser()


  const infoRefTwo = useRef(null);

  const handleClickOutside = (event) => {

    if (infoRefTwo.current && !infoRefTwo.current.contains(event.target)) {
      setOpenInfoTwo(false);
    }
  };

  useEffect(() => {
    if (openInfoTwo) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, [openInfoTwo]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Animate>
          <div className="w-full pt-8 justify-center flex-col space-y-3 px-5">



            <div className="w-full h-[100vh] flex justify-center items-center flex-col   ">
              <div className="w-full h-full flex justify-center items-center ">
                <Canvas className="w-full h-full absolute inset-0" >
                  <OrbitControls enableDamping={true} dampingFactor={0.1} />
                  <PerspectiveCamera makeDefault position={[0, 0, 6]} />
                  <ambientLight intensity={1} />
                  <directionalLight position={[30, 10, 30]} intensity={3} />
                  <directionalLight position={[-20, -10, -20]} intensity={3} />
                  <Suspense fallback={null}>
                    < ZenCoinMod scale={0.3} position={[0, -2, 0]} rotation={[0, Math.sin(Date.now() * 5), 0]} />
                  </Suspense>
                </Canvas>
              </div>
              <div className="w-full text-center flex flex-col items-center justify-center ">


                <h1 className="font-bold text-[32px] text-center">
                  Wallet Bonus Tasks
                </h1>
                <p className='text-[14px] text-[#c6c6c6] leading-[24px] px-6 pb-8'>
                  Connect your Ton wallet and Vote for your favorite Point Payment
                </p>
                <div className="w-full flex flex-col">


                  <VotingCards />

                </div>
              </div>
            </div>
          </div>



          <Outlet />
        </Animate>
      )}
    </>
  );
};

export default Wallet;
