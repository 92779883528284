import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { PiArchiveDuotone, PiHouseDuotone, PiStorefrontDuotone, PiUsersFourDuotone,  } from "react-icons/pi";

// import { IoWalletSharp } from "react-icons/io5";





const Footer = () => {
  const location = useLocation();



const footerLinks = [
  {
    title: "Main",
    link: "/",
    icon: <PiHouseDuotone size={20} className={location.pathname === "/" ? "w-[30px] h-[30px]" : "" } />
},
  {
      title: "Tasks",
      link: "/tasks",
      icon: <PiArchiveDuotone size={20} className={location.pathname === "/tasks" ? "w-[30px] h-[30px]" : ""} />
  },
  {
    title: "Crews",
    link: "/ref",
    icon: <PiUsersFourDuotone size={22} className={location.pathname === "/mongo" ? "w-[30px] h-[30px]" : ""}/>
},
  {
      title: "Store",
      link: "/store",
      icon: <PiStorefrontDuotone size={20} alt="wallet" className={location.pathname === "/wallet" ?  "w-[30px] h-[30px]" : ""}/>
  },
]
  return (
   <div style={{
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  padding: '12px 16px',
  background: 'rgba(18, 18, 18, 0.95)',
  backdropFilter: 'blur(12px)',
  borderTop: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0 -4px 20px rgba(0, 0, 0, 0.3)',
  zIndex: 30,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '80px'
}}>
  {footerLinks.map((footer, index) => (
    <NavLink 
      id="reels"
      key={index}
      to={footer.link}
      className={({ isActive }) => {
        return `
          w-[25%] py-3 flex flex-col items-center justify-center text-[13px] font-medium
          ${
            isActive
              ? "text-[#4CAF50] transform -translate-y-2"
              : "text-[#949494] hover:text-[#bdbdbd]"
          }
          transition-all duration-300 ease-in-out
        `;
      }}
    >
      <div id="reels2" className={`
        flex flex-col items-center justify-center space-y-1
        ${location.pathname === footer.link ? "scale-110" : "scale-100"}
        transition-all duration-300 ease-in-out
      `}>
        <div className={`
          ${location.pathname === footer.link ? "text-[#4CAF50]" : "text-[#949494]"}
          transition-all duration-300 ease-in-out
        `}>
          {footer.icon}
        </div>
        <span className={`
          text-[11px] font-semibold
          ${location.pathname === footer.link ? "opacity-100" : "opacity-70"}
          transition-all duration-300 ease-in-out
        `}>
          {footer.title}
        </span>
      </div>
      {location.pathname === footer.link && (
        <div className="
          absolute -top-1 left-1/2 transform -translate-x-1/2
          w-1 h-1 bg-[#4CAF50] rounded-full shadow-[0_0_8px_#4CAF50]
          transition-all duration-300 ease-in-out
        " />
      )}
    </NavLink>
  ))}
</div>
  );
};

export default Footer;
