import React, { useState, useEffect } from 'react';
import { FaStar, FaInfoCircle } from 'react-icons/fa';

const MiningApp = () => {
  const [balance, setBalance] = useState(14500);
  const [minedTokens, setMinedTokens] = useState(0);
  const [mining, setMining] = useState(false);
  const [hoursMined, setHoursMined] = useState(0);

  useEffect(() => {
    let timer;
    if (mining && hoursMined < 3) {
      timer = setInterval(() => {
        const randomTokens = Math.floor(Math.random() * 10) + 1; // generate a random number between 1 and 10
        setMinedTokens((prev) => prev + randomTokens);
        if (minedTokens + randomTokens >= 400 * hoursMined) {
          setHoursMined((prev) => prev + 1);
        }
      }, 1000); // update every second
    } else {
      setMining(false);
    }
    return () => clearInterval(timer);
  }, [mining, hoursMined, minedTokens]);

  const startMining = () => {
    if (!mining && hoursMined < 3) {
      setMining(true);
    }
  };

  const claimTokens = () => {
    setBalance((prev) => prev + minedTokens);
    setMinedTokens(0);
    setHoursMined(0);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-white" style={{ backgroundColor: '#1a1a1a', fontFamily: 'Arial, sans-serif' }}>
      <div className="bg-gray-800 rounded-full px-4 py-2 flex items-center space-x-2 mb-4">
        <FaStar className="text-white" />
        <span className="text-lg">Balance</span>
        <span className="text-lg font-bold">{balance}</span>
      </div>
      <div className="relative mb-4">
        <img src="https://placehold.co/200x200" alt="Mining fan with a futuristic background" className="rounded-full w-40 h-40 object-cover" />
      </div>
      <div className="bg-gray-800 rounded-lg p-4 text-center mb-4 w-80">
        <div className="text-gray-400">Mined Tokens</div>
        <div className="text-4xl font-bold">{minedTokens.toFixed(2)}</div>
        <div className="text-gray-400 mt-2 flex items-center justify-center space-x-1">
          <FaStar className="text-yellow-500" />
          <span>400 tokens profit per hour</span>
          <FaInfoCircle className="text-gray-400" />
        </div>
      </div>
      <div className="flex space-x-4 mb-4">
        <button onClick={startMining} className="bg-yellow-500 text-black font-bold py-2 px-4 rounded-lg" disabled={mining || hoursMined >= 3}>
          Start Mining
        </button>
        <button onClick={claimTokens} className="bg-gray-700 text-gray-400 font-bold py-2 px-4 rounded-lg" disabled={minedTokens === 0}>
          Claim
        </button>
      </div>
      <button className="bg-white text-black font-bold py-2 px-4 rounded-lg">Boost Mining →</button>
    </div>
  );
};

export default MiningApp;