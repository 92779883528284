import React, { useEffect, useState } from 'react';
import './styles.css'; // Import the CSS file for styles

const AnimatedLogo = () => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    // Set the active state to true to start the animation
    setIsActive(true);
  }, []);

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      style={{ enableBackground: 'new 0 0 500 500' }}
      xmlSpace="preserve"
      width="180"
      height="180"
      className={`animated-logo ${isActive ? 'active' : ''}`} // Always apply active class
    >
      <style type="text/css">
        {`
          .st0{display:none;}
          .st1{display:inline;stroke:#FFFFFF;stroke-width:5;stroke-miterlimit:10;}
          .st2{fill:#FFFFFF;stroke:#FFFFFF;stroke-width:5;stroke-miterlimit:10;}
          .st3{fill:none;stroke:#FFFFFF;stroke-width:5;stroke-miterlimit:10;}
          .st4{fill:none;stroke:#BACD7C;stroke-width:5;stroke-miterlimit:10;}
        `}
      </style>
      <g id="Layer_2">
      </g>
      <g id="Layer_1">
        <g id="Layer_2" className="st0">
          <rect x="24.1" y="33.9" className="st1 svg-elem-1" width="460.8" height="436"></rect>
        </g>
        <g>
          <g>
            <path className="st2 svg-elem-2" d="M136.9,61.1h218.6l14.8,23.6L210.7,381.4H390l-29.5,57.7H136.9l-9.8-21l160.9-298H106.3L136.9,61.1z"></path>
          </g>
          <path className="st3 svg-elem-3" d="M67,309.2l-29.5-57.7l54-102.4H153"></path>
          <path className="st4 svg-elem-4" d="M182.5,149.1h61.4L113.6,390.6l-34.4-57.7"></path>
          <path className="st4 svg-elem-5" d="M386.3,109.7l29.5,57.7L315,351.1h-61.4"></path>
          <path className="st3 svg-elem-6" d="M430.5,193.7l31.9,57.7L406,351.1h-61.5"></path>
        </g>
      </g>
    </svg>
  );
};

export default AnimatedLogo;