import React, { useEffect, useState } from 'react';
import { useUser } from '../context/userContext';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';

import { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { ZenMasks } from './ZenMasks.jsx';
import Spinner from './Spinner.js';

const userLevels = [

  { name: 'Copper Calm', icon: '/bronze.webp', tapBalanceRequired: 1000 },
  { name: 'Serene Silver', icon: '/silver.webp', tapBalanceRequired: 5000 },
  { name: 'Golden Tranquility', icon: '/gold.webp', tapBalanceRequired: 10000 },
  { name: 'Platinum Peace', icon: '/platinum.webp', tapBalanceRequired: 50000 },
  { name: 'Radiant Diamond', icon: '/diamond.webp', tapBalanceRequired: 100000 },
  { name: 'Zen Master', icon: '/master.webp', tapBalanceRequired: 1000000 },


];

const Levels = ({ showLevel, setShowLevel }) => {
  const { tapBalance } = useUser();
  const initialLevelIndex = userLevels.findIndex(level => tapBalance < level.tapBalanceRequired);
  const currentLevelIndex = initialLevelIndex === -1 ? userLevels.length - 1 : initialLevelIndex;

  const [displayedLevelIndex, setDisplayedLevelIndex] = useState(currentLevelIndex);

  const handlePrevious = () => {
    if (displayedLevelIndex > 0) {
      console.log(displayedLevelIndex);
      setDisplayedLevelIndex(displayedLevelIndex - 1);
    }
  };

  const handleNext = () => {
    if (displayedLevelIndex < userLevels.length - 1) {
      setDisplayedLevelIndex(displayedLevelIndex + 1);
    }
  };

  const currentLevel = userLevels[displayedLevelIndex];

  const formatNumberCliam = (num) => {
    if (num < 100000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else if (num < 1000000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else if (num < 10000000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else {
      return (num / 10000000).toFixed(3).replace(".", ".") + " T";
    }
  };
  useEffect(() => {

    // Attach a click event listener to handle the back navigation
    const handleBackButtonClick = () => {
      setShowLevel(false);
    };


    if (showLevel) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(handleBackButtonClick);
    } else {
      window.Telegram.WebApp.BackButton.hide();
      window.Telegram.WebApp.BackButton.offClick(handleBackButtonClick);
    }

    // Cleanup handler when component unmounts
    return () => {
      window.Telegram.WebApp.BackButton.offClick(handleBackButtonClick);

    };
  }, [showLevel, setShowLevel]);

  return (
    <>
      {showLevel && (

        <div className="fixed left-0 right-0 z-20 top-[-12px] bottom-0 flex justify-center taskbg px-[16px] h-full">

          <div className="w-full pt-10 justify-center flex-col space-y-6">

            <div className="flex items-center space-x-4">

              <div className="flex flex-col items-center">
                <h1 className="text-[22px] font-semibold">{currentLevel.name}</h1>
                <p className='text-[15px] text-[#c6c6c6] leading-[24px] w-full text-center px-3 pt-2 pb-[42px]'>
                  Your number of points determines the mask you wear
                </p>
                <div className='w-full relative flex items-center justify-center'>
                  <div className="absolute left-[5px] z-30">
                    {displayedLevelIndex > 0 && (
                      <button className="text-[#b0b0b0] hover:text-[#c4c4c4]" onClick={handlePrevious}>
                        <MdOutlineKeyboardArrowLeft size={40} className='' />
                      </button>
                    )}
                  </div>

                  {showLevel && (

                    <div className="w-full h-[350px] relative flex items-center justify-center">
                      <Canvas>
                        <Suspense fallback={<Spinner />}>
                          <PerspectiveCamera makeDefault position={[0, 0, 5]} />

                          {/* Ambient light for soft overall illumination */}
                          <ambientLight intensity={0.3} />

                          {/* Hemispheric light for a more natural look */}
                          <hemisphereLight
                            skyColor={0xffffff} // Color of the sky
                            groundColor={0x444444} // Color of the ground
                            intensity={0.4} // Overall intensity
                          />

                          {/* Directional lights for focused light */}
                          <directionalLight
                            position={[30, 10, 30]}
                            intensity={0.8} // Adjusted intensity for better highlights
                            castShadow // Enable shadows for depth
                            shadow-mapSize-width={1024} // Higher resolution shadows
                            shadow-mapSize-height={1024}
                            shadow-camera-near={0.5} // Adjust shadow camera settings
                            shadow-camera-far={50}
                            shadow-camera-left={-10}
                            shadow-camera-right={10}
                            shadow-camera-top={10}
                            shadow-camera-bottom={-10}
                          />
                          <directionalLight
                            position={[-20, -10, -20]}
                            intensity={0.5} // Lowered intensity for softer shadows
                            castShadow // Enable shadows for depth
                          />

                          {/* Add a point light for sparkle effect */}
                          <pointLight
                            position={[0, 5, 0]} // Position it above the model
                            intensity={1.5} // Brightness of the point light
                            distance={10} // How far the light reaches
                            decay={2} // How quickly the light fades
                            color={0xffffff} // Color of the light
                          />

                          {/* Optional: Add a spotlight for focused lighting */}
                          <spotLight
                            position={[0, 10, 5]} // Position it above and in front of the model
                            angle={0.3} // Angle of the spotlight
                            penumbra={0.2} // Softness of the edges
                            intensity={1} // Brightness of the spotlight
                            castShadow // Enable shadows for depth
                          />

                          <OrbitControls
                            enableZoom={false}
                            enablePan={false}
                            minPolarAngle={Math.PI / 2}
                            maxPolarAngle={Math.PI / 2}
                            minAzimuthAngle={-Math.PI / 4}
                            maxAzimuthAngle={Math.PI / 4}
                          />

                          <ZenMasks
                            scale={0.009}
                            rotation={[Math.PI / 2, 0, 0]}
                            position={[0, 0, 0]}
                            level={currentLevel}
                          />

                        </Suspense>
                      </Canvas>
                    </div>

                  )}

                  <div className="absolute right-[5px]">
                    {displayedLevelIndex < userLevels.length - 1 && (
                      <button className="text-[#b0b0b0] hover:text-[#c4c4c4]" onClick={handleNext}>
                        <MdOutlineKeyboardArrowRight size={40} className='' />
                      </button>
                    )}
                  </div>

                </div>

                {displayedLevelIndex === currentLevelIndex && displayedLevelIndex < userLevels.length - 1 ? (
                  <>
                    <p className="text-[18px] font-semibold text-[#c6c6c6] px-[20px] pt-[35px] pb-[10px]">{tapBalance} / {formatNumberCliam(currentLevel.tapBalanceRequired)}</p>


                    <div className='w-full px-[44px]'>
                      <div className='flex w-full mt-2 p-[4px] items-center bg-energybar rounded-[10px] border-[1px] border-borders2'>


                        <div className={`h-[8px] rounded-[8px] levelbar`} style={{ width: `${(tapBalance / currentLevel.tapBalanceRequired) * 100}%` }} />
                      </div>

                    </div>



                  </>
                ) : (
                  <>
                    <p className="text-[16px] font-medium text-[#c6c6c6] px-[20px] pt-[35px] pb-[10px]">From {formatNumberCliam(currentLevel.tapBalanceRequired)}</p>

                  </>
                )}
              </div>

            </div>
          </div>

        </div>

      )}
    </>
  );
};

export default Levels;
