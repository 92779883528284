import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-creative';

import './styles.css';

// Import required modules
import { EffectCreative } from 'swiper/modules';
import { useUser } from '../context/userContext';

const Zwiper = () => {
    const { swiperCard, setswiperCard } = useUser(); // Access the swipercard state
    const swiperRef = useRef(null);
    const [isHidden, setIsHidden] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0); // Track the current slide index

    const handleContinue = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext(); // Move to the next slide
        }
    };

    const handleSkip = () => {
        setIsHidden(true);
        setswiperCard(false); // Update the state to hide the swiper
    };

    const handleSlideChange = (swiper) => {
        setCurrentIndex(swiper.activeIndex); // Update current index on slide change
    };

    const handleStart = () => {
        setIsHidden(true);
        setswiperCard(false); // Update the state to hide the swiper
    };

    // Only render the Swiper if swipercard is true
    return (
        <>
            {swiperCard && !isHidden && ( // Check if swipercard is true
                <div className='w-full z-[60] fixed left-0 zwiperSix right-0 top-0 bottom-0 flex flex-col items-center justify-center'>
                    <Swiper
                        ref={swiperRef}
                        grabCursor={true}
                        effect={'creative'}
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-20%', 0, -1],
                            },
                            next: {
                                translate: ['100%', 0, 0],
                            },
                        }}
                        modules={[EffectCreative]}
                        className='mySwiper3 w-full h-[90vh]'
                        onSlideChange={handleSlideChange} // Track slide changes
                    >
                        {/* Swiper slides */}
                        <SwiperSlide className='bg-[#000] w-full flex items-center justify-center flex-col'>
                            <div className="text-center text-white p-6 rounded-lg shadow-lg">
                                <img
                                    src="/welcomez.png"
                                    alt="Black double doors with orange handles, with floating coins in the background"
                                    className="w-full h-[50vh] mb-6"
                                />
                                <h1 className="text-2xl  font-extrabold mb-4 tracking-wide">WELCOME TO ZENIVERSE</h1>
                                <p className="text-[14px] text-[#c6c6c6] leading-[24px] px-6 pb-8">
                                    Step into Zeniverse, where rewards await. Tap, invite, complete tasks, and play mini-games to earn Zen Points. Your journey to balance begins here!
                                </p>

                            </div>
                        </SwiperSlide>

                        <SwiperSlide className='bg-[#000] w-full flex items-center justify-center flex-col'>
                            <div className="text-center text-white p-6 rounded-lg shadow-lg">
                                <img
                                    src="/holdz.png"
                                    alt="Black double doors with orange handles, with floating coins in the background"
                                    className="w-full h-[50vh] mb-6"
                                />
                                <h1 className="text-2xl  font-extrabold mb-4 tracking-wide">TAP INTO TRANQUILITY</h1>
                                <p className="text-[14px] text-[#c6c6c6] leading-[24px] px-6 pb-8">
                               Hold for 2 Seconds to Channel your Mana to gather Zen Points. Elevate your journey with mindful upgrades.
                                </p>

                            </div>
                        </SwiperSlide>


                        <SwiperSlide className='bg-[#000] w-full flex items-center justify-center flex-col'>
                            <div className="text-center text-white p-6 rounded-lg shadow-lg">
                                <img
                                    src="/frenz.png"
                                    alt="Black double doors with orange handles, with floating coins in the background"
                                    className="w-full h-[50vh] mb-6"
                                />
                                <h1 className="text-2xl  font-extrabold mb-4 tracking-wide">SHARE THE PATH</h1>
                                <p className="text-[14px] text-[#c6c6c6] leading-[24px] px-6 pb-8">
                                Invite friends to Zeniverse and receive 10% of their harmonious earnings.
                                </p>

                            </div>
                        </SwiperSlide>


                        <SwiperSlide className='bg-[#000] w-full flex items-center justify-center flex-col '>
                            <div className="text-center text-white p-6 rounded-lg shadow-lg">
                                <img
                                    src="/taskz.png"
                                    alt="Black double doors with orange handles, with floating coins in the background"
                                    className="w-full h-[50vh] mb-6"
                                />
                                <h1 className="text-2xl  font-extrabold mb-4 tracking-wide">FULFILL TASKS</h1>
                                <p className="text-[14px] text-[#c6c6c6] leading-[24px] px-6 pb-8">
                                Embrace tasks to enhance your Zen Points and deepen your experience.
                                </p>

                            </div>

                        </SwiperSlide>


                        <SwiperSlide className='bg-[#000] w-full flex items-center justify-center flex-col '>
                            <div className="text-center text-white p-6 rounded-lg shadow-lg">
                                <img
                                    src="/minigz.png"
                                    alt="Black double doors with orange handles, with floating coins in the background"
                                    className="w-full h-[50vh] mb-6 bg-[#777]"
                                />
                                <h1 className="text-2xl  font-extrabold mb-4 tracking-wide">EXPLORE MINI-GAMES</h1>
                                <p className="text-[14px] text-[#c6c6c6] leading-[24px] px-6 pb-8">
                                Enter mini-games to unlock greater rewards and balance your Zen flow.
                                </p>

                            </div>
                        </SwiperSlide>


                        <SwiperSlide className='bg-[#000] w-full flex items-center justify-center flex-col '>
                            <div className="text-center text-white p-6 rounded-lg shadow-lg">
                                <img
                                    src="/whyz.png"
                                    alt="Black double doors with orange handles, with floating coins in the background"
                                    className=" w-full h-[50vh] mb-6 "
                                />
                                <h1 className="text-2xl  font-extrabold mb-4 tracking-wide">WHY JOIN ZENIVERSE?</h1>
                                <p className="text-[14px] text-[#c6c6c6] leading-[24px] px-6 pb-8">
                                Step into Zeniverse, where rewards await. Tap, invite, complete tasks, and play mini-games to earn Zen Points. Your journey to balance begins here!
                                </p>

                            </div>
                        </SwiperSlide>
                    </Swiper>

                    {/* Buttons for all slides except the last one */}
                    <div className='flex space-x-5 mb-4 bg-[#000] h-[10vh] w-full justify-center items-center'> {/* Adjusted margin */}
                        {currentIndex < 5 ? (
                            <>

                                <button
                                    onClick={handleSkip}
                                    className='w-[25vw] flex items-center justify-center h-[50px] bg-red-500 text-[#fff] font-semibold rounded-lg shadow-lg transition duration-200 hover:bg-red-400'
                                >
                                    Skip
                                </button>

                                <button
                                    onClick={handleContinue}
                                    className='w-[60vw] flex items-center justify-center h-[50px] bg-accent text-[#000] font-semibold rounded-lg shadow-lg transition duration-200 hover:bg-opacity-80'
                                >
                                    Continue
                                </button>

                            </>
                        ) : (
                            // Only show Start button on the last slide
                            <button
                                onClick={handleStart}
                                className='w-[95vw] flex items-center justify-center h-[50px] bg-green-500 text-[#fff] font-semibold rounded-lg shadow-lg transition duration-200 hover:bg-green-400'
                            >
                                Start
                            </button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Zwiper;