/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber';
import React, { useRef } from 'react'

export function ZenCoinMod(props, {onPointerDown}) {
  const { nodes, materials } = useGLTF('/zencoin.glb')

 
  const meshRef = useRef();

  useFrame((state) => {
    if (meshRef.current) {
      meshRef.current.rotation.y = Math.sin(state.clock.elapsedTime) * 0.2;
    }
  });

  return (
    <group ref={meshRef} onPointerDown={onPointerDown} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text.geometry}
        material={materials['Material.001']}
        position={[0.043, 7.378, -0.872]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={3.962}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text001.geometry}
        material={materials['Material.001']}
        position={[0.043, 7.378, 1.053]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={3.962}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder001.geometry}
        material={materials['Material.002']}
        position={[0.037, 7.32, 0.112]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[6.83, 1.018, 6.83]}
      />
    </group>
  )
}

useGLTF.preload('/zencoin.glb')



