import React, { useEffect, useRef, useState } from "react";
import Animate from "../Components/Animate";
import { Outlet, useNavigate } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { db } from '../firebase/firestore';
import { useUser } from "../context/userContext";
import { PiRocketLaunchFill } from "react-icons/pi";
import { GiMagicHat, GiMagickTrick, GiMagicLamp, GiMagicPalm, GiMagicPortal, GiMagicSwirl, } from "react-icons/gi";
import { MdBatteryCharging90 } from "react-icons/md";
// import { IoIosFlash } from "react-icons/io";
import { RiArrowRightDoubleFill } from "react-icons/ri";
import Levels from "../Components/Levels";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { doc, updateDoc } from 'firebase/firestore';
import { IoClose } from "react-icons/io5";
import { ImFire, } from "react-icons/im";
import { IoIosFlash } from "react-icons/io";
import { FaRobot } from "react-icons/fa6";



const userLevels = [

  { id: 1, name: 'Copper Calm', icon: '/bronze.webp', tapBalanceRequired: 1000 },
  { id: 2, name: 'Serene Silver', icon: '/silver.webp', tapBalanceRequired: 5000 },
  { id: 3, name: 'Golden Tranquility', icon: '/gold.webp', tapBalanceRequired: 10000 },
  { id: 4, name: 'Platinum Peace', icon: '/platinum.webp', tapBalanceRequired: 50000 },
  { id: 5, name: 'Radiant Diamond', icon: '/diamond.webp', tapBalanceRequired: 100000 },
  { id: 6, name: 'Zen Master', icon: '/master.webp', tapBalanceRequired: 1000000 },

];



const tapValues = [
  {
    level: 1,
    value: 0.5,
  },
  {
    level: 2,
    value: 1,
  },
  {
    level: 3,
    value: 1.5,
  },
  {
    level: 4,
    value: 2,
  },
  {
    level: 5,
    value: 2.5,
  },
  {
    level: 6,
    value: 3,
  },
  {
    level: 7,
    value: 3.5,
  },
  {
    level: 8,
    value: 4,
  },
  {
    level: 9,
    value: 4.5,
  },
  {
    level: 10,
    value: 5,
  },
 
];

const energyValues = [
  {
    level: 1,
    energy: 50,
  },
  {
    level: 2,
    energy: 100,
  },
  {
    level: 3,
    energy: 150,
  },
  {
    level: 4,
    energy: 200,
  },
  {
    level: 5,
    energy: 250,
  },
  {
    level: 6,
    energy: 300,
  },
  {
    level: 7,
    energy: 350,
  },
  {
    level: 8,
    energy: 400,
  },
  {
    level: 9,
    energy: 450,
  },
  {
    level: 10,
    energy: 500,
  },
  {
    level: 11,
    energy: 550,
  },
  {
    level: 12,
    energy: 600,
  },
  {
    level: 13,
    energy: 650,
  },
  {
    level: 14,
    energy: 700,
  },
];


const upgradeCosts = [0, 200, 500, 1000, 5000, 10000, 30000, 50000, 100000, 150000];


const energyUpgradeCosts = [0, 1000, 2500, 3500, 4500, 5500, 6500, 7500, 8500, 10000, 15000, 25000, 35000, 45000];


const Boost = () => {
  const { level, balance, id, tapValue, setTapValue, setMainTap, tapBalance, startTimer, setTapGuru, setFreeGuru, freeGuru, battery, setEnergy, setBattery, setBalance, refBonus } = useUser();
  const [showLevel, setShowLevel] = useState();
  const [openInfo, setOpenInfo] = useState(false);
  const [openInfoTwo, setOpenInfoTwo] = useState(false);
  const [isUpgradeModalVisible, setIsUpgradeModalVisible] = useState(false);
  const [isUpgradeModalVisibleEn, setIsUpgradeModalVisibleEn] = useState(false);
  const [congrats, setCongrats] = useState(false)
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [isUpgradingEn, setIsUpgradingEn] = useState(false);
  const location = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);


  const infoRef = useRef(null);
  const infoRefTwo = useRef(null);

  const handleClickOutside = (event) => {
    if (infoRef.current && !infoRef.current.contains(event.target)) {
      setOpenInfo(false);
    }
    if (infoRefTwo.current && !infoRefTwo.current.contains(event.target)) {
      setOpenInfoTwo(false);
    }
  };

  useEffect(() => {
    if (openInfo || openInfoTwo) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openInfo, openInfoTwo]);

  const openit = () => {
    setOpenInfo(true);
  }

  // const formatNumber = (num) => {
  //   if (num < 100000) {
  //     return new Intl.NumberFormat().format(num).replace(/,/g, " ");
  //   } else if (num < 1000000) {
  //     return new Intl.NumberFormat().format(num).replace(/,/g, " K");
  //   } else {
  //     return (num / 1000000).toFixed(3).replace(".", ".") + " M";
  //   }
  // };


  const formatNumber = (number) => {
    if (number === undefined || number === null || isNaN(number)) {
      return '';
    }

    if (number >= 1000000) {
      return (number / 1000000).toFixed() + 'M';
    } else if (number >= 100000) {
      return (number / 1000).toFixed(0) + 'K';
    } else {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  };

  const handleUpgrade = async () => {
    if (isUpgrading) return; // Prevent multiple clicks
    setIsUpgrading(true);
    const nextLevel = tapValue.level;
    const upgradeCost = upgradeCosts[nextLevel];
    if (nextLevel < tapValues.length && (balance + refBonus) >= upgradeCost && id) {
      const newTapValue = tapValues[nextLevel];
      const userRef = doc(db, 'telegramUsers', id.toString());
      try {
        await updateDoc(userRef, {
          tapValue: newTapValue,
          balance: balance - upgradeCost
        });
        setTapValue(newTapValue);
        setBalance((prevBalance) => prevBalance - upgradeCost);
        setIsUpgrading(false);
        setIsUpgradeModalVisible(false);
        setCongrats(true)
        setIsDisabled(true);
        setTimeout(() => {
          setCongrats(false)
          setIsDisabled(false);
        }, 4000)
        console.log('Tap value upgraded successfully');
      } catch (error) {
        console.error('Error updating energy value:', error);
      } finally {
        setIsUpgrading(false); // Re-enable the button
      }
    } else {
      setIsUpgrading(false); // Re-enable the button if conditions are not met
    }

  };

  const handleEnergyUpgrade = async () => {
    if (isUpgradingEn) return; // Prevent multiple clicks
    setIsUpgradingEn(true);

    const nextEnergyLevel = battery.level;
    const energyUpgradeCost = energyUpgradeCosts[nextEnergyLevel];

    if (nextEnergyLevel < energyValues.length && (balance + refBonus) >= energyUpgradeCost && id) {
      const newEnergyValue = energyValues[nextEnergyLevel];
      const userRef = doc(db, 'telegramUsers', id.toString());

      try {
        await updateDoc(userRef, {
          battery: newEnergyValue,
          balance: balance - energyUpgradeCost,
          energy: newEnergyValue.energy,
        });

        setBattery(newEnergyValue);
        localStorage.setItem('energy', newEnergyValue.energy);
        setEnergy(newEnergyValue.energy);
        setBalance((prevBalance) => prevBalance - energyUpgradeCost);

        setIsUpgradeModalVisibleEn(false);
        setIsDisabled(true);
        setCongrats(true);
        setTimeout(() => {
          setCongrats(false);
          setIsDisabled(false);
        }, 4000);

        console.log('Energy value upgraded successfully');
        console.log('Energy value upgraded successfully +', newEnergyValue.value);
      } catch (error) {
        console.error('Error updating energy value:', error);
      } finally {
        setIsUpgradingEn(false); // Re-enable the button
      }
    } else {
      setIsUpgradingEn(false); // Re-enable the button if conditions are not met
    }
  };


  const nextUpgradeCost = upgradeCosts[tapValue.level];
  const hasSufficientBalance = (balance + refBonus) >= nextUpgradeCost;

  const nextEnergyUpgradeCost = energyUpgradeCosts[battery.level];
  const hasSufficientBalanceEn = (balance + refBonus) >= nextEnergyUpgradeCost;


  const handleTapGuru = async () => {
    if (id) {
      if (freeGuru > 0) {
        try {
          const newRemainingClicks = freeGuru - 1;
          setFreeGuru(newRemainingClicks);

          // Update the Firestore document
          const userRef = doc(db, 'telegramUsers', id.toString());
          await updateDoc(userRef, {
            freeGuru: newRemainingClicks,
            timeSta: new Date(),
          });

          startTimer();
          setMainTap(false);
          setTapGuru(true);
          location('/'); // Navigate to /home without refreshing the page
          setCongrats(true);
          setIsDisabled(true);
          setTimeout(() => {
            setCongrats(false);
            setIsDisabled(false);
          }, 2000);
        } catch (error) {
          console.error('Error updating Firestore document:', error);
        }
      } else {
        setIsDisabled(true);
      }
    }
  };


  const calculateTimeRemaining = () => {
    const now = new Date();
    const nextDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    const timeDiff = nextDate - now;

    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return { hours, minutes, seconds };
  };
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);



  const initialLevelIndex = userLevels.findIndex(level => tapBalance < level.tapBalanceRequired);
  const currentLevelIndex = initialLevelIndex === -1 ? userLevels.length - 1 : initialLevelIndex;

  const displayedLevelIndex = currentLevelIndex
  const currentLevel = userLevels[displayedLevelIndex];



  return (
    <>

      <Animate>
        <div className="w-full pt-0 justify-center flex-col space-y-3">

          <div className='w-full flex justify-between px-4'>

            <button onClick={() => setShowLevel(true)} className='w-[55%] flex space-x-1 items-center'>
              <span className='flex items-center justify-center'>

                <img alt="daxy" src={level.imgUrl} className='w-[16px] levelImg h-full' />

              </span>
              <span className='levelName font-medium text-secondary text-[15px] flex items-center space-x-1'>
                <span className='text-[15px]'> {level.name}</span>
                <span className='flex items-center'>  <RiArrowRightDoubleFill size={18} className='' /> </span>
              </span>
            </button>




            <div className='w-fit py-[2px] px-3 flex items-center space-x-1 justify-center border-[1px] border-cards rounded-[10px]'>
              <span className='w-[14px] levelImg'>
                <img alt="daxy" src='/loader.webp' className='w-full' />
              </span>
              <h1 className="text-[15px] font-bold">
                {formatNumber(balance + refBonus)}
              </h1>
            </div>

          </div>

          <div className="pb-1 px-4">
            <h1 className='text-[24px] font-semibold pb-1'>
              Purchase Enhancements
            </h1>
            <p className='text-[14px] leading-[24px] pr-6'>
              Buy enhancements to earn more Zens
            </p>
          </div>


          <div className='w-full relative h-screen bg-divider  border-[0.5px] border-transparent bordercut '>
            <div id="refer" className='w-full h-screen homescreen  mt-[2px] px-5 pt-1'>

              <div className="w-full flex flex-col overflow-y-auto pb-[100px] h-[80vh] scroller">

                <button
                  onClick={() => setIsUpgradeModalVisible(true)}
                  disabled={tapValue.level >= tapValues.length}
                  className="w-full h-[100px] flex justify-center items-center p-3   mt-4 justify-between space-y-1 bg-cards3 rounded-[10px]">

                  <div className="flex space-x-2">
                    <GiMagickTrick size={45} className="text-accent2 boostImg" />

                    <span className="font-medium boostTitle text-[15px] items-start text-left flex flex-col space-y-[2px]">
                      <span className=""> Zen Limit</span>
                      <p className="text-[11px] text-[#d0d0d0] text-left">
                        Level {tapValue.level}
                      </p>
                    </span>

                  </div>

                  <div className="flex items-center space-x-2">
                    <span className="text-cardtext boostAmount font-semibold text-[15px] pl-1 flex items-center justify-between w-full">
                      <span> -{tapValue.level >= tapValues.length ? (
                        <>
                          MAX
                        </>
                      ) : (
                        <>
                          {formatNumber(nextUpgradeCost)}
                        </>
                      )} ZEN </span>
                      <MdOutlineKeyboardArrowRight size={30} className='text-[#959595]' />
                    </span>

                  </div>

                </button>

                <button
                  onClick={() => setIsUpgradeModalVisibleEn(true)}
                  disabled={battery.level >= energyValues.length}
                  className="w-full h-[100px] justify-center items-center  p-3 mt-4  flex justify-between space-y-1 bg-cards3 rounded-[10px]">

                  <div className="flex space-x-2">
                    <GiMagicSwirl size={45} className="text-accent2 boostImg" />
                    <span className="font-medium boostTitle text-[15px] items-start text-left flex flex-col space-y-[2px]">
                      <span> Mana Limit</span>
                      <p className="text-[11px] text-[#d0d0d0] text-left">
                        Level {battery.level}
                      </p>
                    </span>

                  </div>

                  <div className="flex items-center space-x-2">
                    <span className="text-cardtext boostAmount font-semibold text-[15px] pl-1 flex items-center justify-between w-full">
                      <span>

                        -{battery.level >= energyValues.length ? (
                          <>
                            MAX
                          </>
                        ) : (
                          <>
                            {formatNumber(nextEnergyUpgradeCost)} ZEN
                          </>
                        )}


                      </span>
                      <MdOutlineKeyboardArrowRight size={30} className='text-[#959595]' />
                    </span>
                  </div>

                </button>



                {/*  */}
                <div className="w-full flex flex-col space-y-2 mt-4 pt-4 items-center">



                  {/*  */}

                  <div onClick={openit} class="isolate cardios aspect-video w-full rounded-xl relative overflow-hidden p-6 z-10">


                    <div className="backdrop-blur-[10px] bg-cards absolute left-0 right-0 top-0 bottom-0 z-0 pointer-events-none" />

                    <div className="flex w-full flex-col relative z-10 space-y-1">
                      <div className="flex justify-between mt-[5px] ">
                        <h4 className="text-[16px] xxTitle text-[#f2f2f2] font-semibold uppercase">
                          Balance boost spell
                        </h4>

                        <span className="not-italic text-[16px] text-accent flex items-center ">
                          <span>x2</span>
                          <IoIosFlash size={20} className="" />
                        </span>

                      </div>

                      <span className="text-[26px] xxAmount text-primary font-semibold flex items-center flex justify-between space-x-2">
                        <span>{formatNumber(balance + refBonus)} ZEN</span>


                        <button className="text-btn4 py-1 px-3 bg-[#ffffff] rounded-[5px] font-medium text-[13px] w-fit">
                          Learning spell...
                        </button>
                      </span>



                    </div>

                  </div>

                  {/*  */}

                </div>


              </div>
            </div>
          </div>



          <div className='w-full absolute top-[50px] left-0 right-0 flex justify-center z-50 pointer-events-none select-none'>
            {congrats ? (<img src='/congrats.gif' alt="congrats" className="w-[80%]" />) : (<></>)}
          </div>



          {/* Upgrade Modal */}
          <div
            className={`${isUpgradeModalVisible ? "visible" : "invisible"
              } fixed top-[-12px] bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#00000042] flex justify-center items-center backdrop-blur-[6px] px-4`}
          >
            <div className={`${isUpgradeModalVisible ? "opacity-100 mt-0 ease-in duration-300" : "opacity-0 mt-[100px]"
              } w-full bg-modal relative rounded-[16px] flex flex-col justify-center p-8`}>
              <div className="w-full flex justify-center flex-col items-center space-y-3">
                <button
                  onClick={() => setIsUpgradeModalVisible(false)}
                  className="flex items-center justify-center absolute right-8 top-8 text-center rounded-[12px] font-medium text-[16px]"
                >
                  <IoClose size={24} className="text-[#9a96a6]" />
                </button>
                <div className="w-full items-center justify-center flex flex-col pt-[20px]">


                </div>
                <h3 className="font-medium text-[22px] pt-2 !mt-[2px]">
                  Zen Limit level <span className='text-accent'>{tapValues[tapValue.level]?.value}</span>
                </h3>
                <span className="flex items-center space-x-1 !mt-[4px]">
                
                  <span className='font-semibold text-[17px]'> -{formatNumber(nextUpgradeCost)} ZEN</span>
                </span>

                <p className="pb-6 text-[14px] font-medium w-full text-center">
                  Increase the amount of ZEN you can earn per one tap. <br />
                  +0.5 per tap for each level.
                </p>
                <div className="w-full flex justify-center">
                  <button
                    onClick={handleUpgrade}
                    disabled={!hasSufficientBalance}
                    className={`${!hasSufficientBalance ? 'bg-[#0000004a] text-[#888]' : 'bg-btn4 text-[#000]'} ${isDisabled === true ? 'pointer-events-none' : 'pointer-events-auto'} w-full py-[14px] px-6 flex items-center justify-center text-center rounded-[12px] font-semibold text-[16px]`}
                  >
                    {isUpgrading ? 'Enhancing...' : hasSufficientBalance ? 'Enhance' : 'Insufficient ZEN'}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Energy Upgrade Modal */}
          <div
            className={`${isUpgradeModalVisibleEn ? "visible" : "invisible"
              } fixed top-[-12px] bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#00000042] flex justify-center items-center backdrop-blur-[6px] px-4`}
          >
            <div className={`${isUpgradeModalVisibleEn ? "opacity-100 mt-0 ease-in duration-300" : "opacity-0 mt-[100px]"
              } w-full bg-modal relative rounded-[16px] flex flex-col justify-center p-8`}>
              <div className="w-full flex justify-center flex-col items-center space-y-3">
                <button
                  onClick={() => setIsUpgradeModalVisibleEn(false)}
                  className="flex items-center justify-center absolute right-8 top-8 text-center rounded-[12px] font-medium text-[16px]"
                >
                  <IoClose size={24} className="text-[#9a96a6]" />
                </button>
                <div className="w-full items-center justify-center flex flex-col pt-[20px]">
                  

                </div>
                <h3 className="font-medium text-[22px] pt-2 !mt-[2px]">
                  Mana Limit level <span className='text-accent'>{energyValues[battery.level]?.level}</span>
                </h3>
                <span className="flex items-center space-x-1 !mt-[4px]">
                  <span className='font-semibold text-[17px]'>-{formatNumber(nextEnergyUpgradeCost)} ZEN</span>
                </span>

                <p className="pb-6 text-[14px] font-medium w-full text-center">
                  Increase the limit of your mana storage. <br />
                  +50 mana limit for each level.
                </p>
                <div className="w-full flex justify-center">
                  <button
                    onClick={handleEnergyUpgrade}
                    disabled={!hasSufficientBalanceEn}
                    className={`${!hasSufficientBalanceEn ? 'bg-[#0000004a] text-[#888]' : 'bg-btn4 text-[#000]'} ${isDisabled ? 'pointer-events-none' : 'pointer-events-auto'} w-full py-[14px] px-6 flex items-center justify-center text-center rounded-[12px] font-semibold text-[16px]`}
                  >
                    {isUpgradingEn ? 'Enhancing...' : hasSufficientBalanceEn ? 'Enhance' : 'Insufficient ZEN'}
                  </button>
                </div>
              </div>
            </div>
          </div>


          <div
            className={`${openInfo === true ? "visible" : "invisible"
              } fixed top-[-12px] bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#00000042] flex justify-center items-center backdrop-blur-[6px] px-4`}
          >


            <div ref={infoRef} className={`${openInfo === true ? "opacity-100 mt-0 ease-in duration-300" : "opacity-0 mt-[100px]"
              } w-full bg-modal relative rounded-[16px] flex flex-col justify-center p-8`}>
              <div className="w-full flex justify-center flex-col items-center space-y-3">
                <div className="w-full items-center justify-center flex flex-col space-y-2">
                 
                  <p className='font-bold  text-[20px]'>x2 ZEN BALANCE</p>
                </div>
                <h3 className="font-medium text-[17px] text-[#ffffff] pt-2 pb-2 uppercase">
                  Zen boost spell
                </h3>
                <p className="pb-6 text-[#bfbfbf] text-[15px] w-full text-center">

                  This spell allows you to get double of your earnings before listing date. Stay prepared and keep claiming your zens while you await the airdrop date
                </p>

              </div>

              <div className="w-full flex justify-center">
                <button
                  onClick={() => setOpenInfo(false)}
                  className="bg-btn4 w-fit py-[10px] px-6 flex items-center justify-center text-center rounded-[12px] font-medium text-[16px]"
                >
                  Back to Enhancements
                </button>
              </div>
            </div>
          </div>


          <Levels showLevel={showLevel} setShowLevel={setShowLevel} />
          {currentLevel && (
            <>
            </>
          )}

        </div>
        <Outlet />
      </Animate>

    </>
  );
};

export default Boost;
