import React, { useEffect } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { PiSmileySadLight } from "react-icons/pi";

function ErrorCom() {
    let navigate = useNavigate();

  useEffect(() => {
    if (navigate) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = '';
      
    };
    }, [navigate]);

    const goBack = () => {
      navigate(-1);
    };
  
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50" onClick={goBack}>
    <div className="relative w-[95%] md:w-[70%] lg:w-[50%] h-auto bg-gradient-to-r from-[#1e2340] to-[#2a2d4d] rounded-lg shadow-lg overflow-hidden">
        <div className="absolute inset-0 bg-opacity-10 backdrop-blur-md"></div>
        <div className="relative p-6 flex flex-col items-center text-white">
            <button className="absolute top-4 right-4 bg-gray-800 rounded-full p-2 shadow-lg hover:bg-gray-700 transition duration-300" onClick={goBack}>
                <MdOutlineClose size="24px" className="text-white" />
            </button>
            <div className="flex flex-col items-center">
                <PiSmileySadLight size="60px" className="text-yellow-400 animate-bounce mb-4" />
                <h1 className="text-2xl font-semibold">C'mon! Something went wrong </h1>
                <p className="mt-2 text-lg text-center">Looks like something went wrong. This App works can't say much for your internet .</p>
                <p className="mt-4 text-sm text-center text-gray-300">Please click the button below to go back or just reload the app.</p>
                <button className="mt-6 bg-blue-600 text-white py-2 px-6 rounded-lg shadow-md hover:bg-blue-500 transition duration-300" onClick={goBack}>
                    Go Back
                </button>
            </div>
        </div>
    </div>
</div>
  );
}

export default ErrorCom;