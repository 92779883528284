import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firestore'; // Ensure you have your Firebase config set up
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { useUser } from '../context/userContext'; // Import the useUser  context
import { Link } from 'react-router-dom';

const ComingSoonStore = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isEmailRegistered, setIsEmailRegistered] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);

    const { id } = useUser(); // Replace with your actual user ID logic

    useEffect(() => {
        const checkEmailExists = async () => {
            if (id && email) {
                const userDocRef = doc(db, 'telegramUsers', id);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    if (userData.email === email) {
                        setIsEmailRegistered(true);
                    } else {
                        setIsEmailRegistered(false);
                    }
                }
            }
        };

        checkEmailExists();
    }, [email, id]);

    const handleEmailChange = (e) => {
        const inputEmail = e.target.value;
        setEmail(inputEmail);

        // Validate email format
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsEmailValid(emailPattern.test(inputEmail));
    };

    const handleNotifyMe = async () => {
        if (!isEmailValid) {
            setMessage("Invalid email address.");
            return;
        }

        if (!id) {
            setMessage("You must be logged in to receive notifications.");
            return;
        }

        if (isEmailRegistered) {
            setMessage(`Email ${email} is already registered.`);
            return;
        }

        try {
            // Create a document in the users collection with the userId
            const userDocRef = doc(db, 'telegramUsers', id);
            await setDoc(userDocRef, { email }, { merge: true }); // Use merge to avoid overwriting other user data

            setMessage(`Email ${email} has been added to your profile. We will notify you when it is ready!`);
            setEmail(''); // Clear the input
            setIsEmailRegistered(true); // Set as registered
        } catch (error) {
            console.error("Error adding email: ", error);
            setMessage("There was an error. Please try again.");
        }
    };

    return (
        <div id="refer" className='w-full h-screen mt-[2px] px-5'>
			<div className='scroller h-[100vh] overflow-y-auto mt-5 pb-[150px]'>
        <div className="w-full flex items-center justify-center h-screen ">
            <div className="text-center text-white">
                <h1 className="text-3xl font-bold mb-4">Coming Soon!</h1>
                <p className="text-x mb-8">We are working hard to bring you our store.</p>
                <p className="text-x mb-8">Add your Email and Vote.</p>
                <div className="flex flex-col rounded justify-center mb-4">
                    <input
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="Enter your email"
                        className=" w-full h-[50px] text-black p-2 rounded focus:outline-none mb-5"
                    />
                    <button
                        onClick={handleNotifyMe}
                        className={`w-full h-[50px] bg-green-500 p-2 rounded mt-3 font-bold hover:bg-yellow-400 ${isEmailRegistered ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={isEmailRegistered}
                    >
                        {isEmailRegistered ? 'Exists' : 'Notify Me'}
                    </button>
                </div>
                {message && <p className="text-sm mt-4">{message}</p>}
                <p className="text-sm">Stay tuned for updates!</p>


                <Link
                    className='w-full bg-cards mt-8 h-[50px] rounded flex items-center justify-center text-center'

                    to="/wallet" // Set the destination path
                >
                    Vote Now
                </Link>


            </div>
        </div>
        </div>
        </div>
    );
};

export default ComingSoonStore;